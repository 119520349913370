import React from "react";
import "./whyAPScholars.css";

const WHYAPScholars = () => {
  return (
    <div className="container mb-5 mt-5">
      <div className="mb-5">
        <h1 className="why-us">WHY APSCHOLARS ?</h1>
        <p className="sub-paragrapgh">
          AP Scholars provides a unique Q&A format to boost skills in math and
          computer science through engaging, interactive learning. Our
          comprehensive learning tracks feature high-definition video content
          and hands-on programming tasks, fostering skill development and
          practical understanding
        </p>
      </div>

      <div className="row text-center g-4">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="card-icons" src="/assets/build.png" alt="Build" />
            <div className="card-body">
              <h2 className="card-title">Build Solid Foundation</h2>
              <p className="card-p">Crystal clear understanding of concepts</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="card-icons" src="/assets/logic.png" alt="Logic" />
            <div className="card-body">
              <h2 className="card-title">Learn To Write Logic</h2>
              <p className="card-p">How to logically think about a problem</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img
              className="card-icons"
              src="/assets/profession.png"
              alt="byProfessionals"
            />
            <div className="card-body">
              <h2 className="card-title">Design By Professionals</h2>
              <p className="card-p">
                Years of experience working in their careers
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="card-icons" src="/assets/qa.png" alt="QA" />
            <div className="card-body">
              <h2 className="card-title">Q & A Based Teaching</h2>
              <p className="card-p">
                We work through 1000s of progressive questions
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img
              className="card-icons"
              src="/assets/problemSolving.png"
              alt="problemSolving"
            />
            <div className="card-body">
              <h2 className="card-title">Problem Solving</h2>
              <p className="card-p">
                Learn strategies/techniques for solving problems
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="card-icons" src="/assets/design.png" alt="Design" />
            <div className="card-body">
              <h2 className="card-title">Design For Kids</h2>
              <p className="card-p">
                Designed for kids by kids and professionals
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WHYAPScholars;
