import React, { useState, useEffect } from "react";
import "./my-profile.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useGetUserQuery, useSaveUserMutation } from "../../../redux/apis/rtk";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const { user, isLogin } = useSelector((state) => state.users);
  const [isEdit, setEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  const { data: userProfile, isLoading } = useGetUserQuery();
  console.log("check user profile ", userProfile)
  const [saveUser] = useSaveUserMutation();
  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    zipcode: "",
    city: "",
    state: "",
    schooldistrict: "",
    schoolname: "",
    profileImageUrl: ""
  });

  // Update initial values once userProfile data is available
  useEffect(() => {
    if (userProfile) {
      setInitialValues({
        firstname: userProfile.firstName || "",
        lastname: userProfile.lastName || "",
        email: userProfile.email || "",
        phonenumber: userProfile.mobileNo || "",
        zipcode: userProfile.zipCode || "",
        city: userProfile.city || "",
        state: userProfile.state || "",
        schooldistrict: userProfile.schoolDistrict || "",
        schoolname: userProfile.schoolName || "",
      });
      setSelectedImage(userProfile.profileImageUrl || "/assets/useravatar.png");
    }
  }, [userProfile]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is required!"),
    phonenumber: Yup.string().required("Mobile number is required!"),
    zipcode: Yup.string().required("Zipcode is required!"),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    
    // Append all necessary fields to FormData
    formData.append("FirstName", values.firstname);
    formData.append("LastName", values.lastname);
    formData.append("MobileNo", values.phonenumber);
    formData.append("ZipCode", values.zipcode);
    formData.append("SchoolDistrict", values.schooldistrict);
    formData.append("SchoolName", values.schoolname);
    formData.append("City", values.city);
    formData.append("State", values.state);
    formData.append("email", values.email);
    
    if (selectedImage && selectedImage !== userProfile.profileImageUrl) {
      formData.append("ProfileImage", selectedImage);
    }
    try {
      await saveUser(formData).unwrap();
      setEdit(false);
      console.log("Profile updated successfully!");
    } catch (error) {
      console.error("Failed to update profile: ", error);
    }
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const onEdit = () => {
    setEdit(!isEdit);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="mt-4 profile-info">
        <div className="p-3 d-flex justify-content-between">
          <h2 className="color">Information</h2>
          <div className="">
            {/* {isEdit && (
              <img
                className="me-3 edit_icon"
                src="/assets/save.svg"
                alt="save"
              />
            )} */}
            <button onClick={onEdit} className="btn">
              <img
                className="edit_icon"
                src={isEdit ? "/assets/close.svg" : "/assets/edit.svg"}
                alt={isEdit ? "close" : "edit"}
              />
            </button>
          </div>
        </div>
        <div className="ps-3 pe-3 row">
          <div className="col-lg-2 col-md-2">
            <img
              className="student_Image"
              src={
                selectedImage instanceof File
                  ? URL.createObjectURL(selectedImage)
                  : selectedImage
              }
              alt="studentImage"
            />
            {isEdit && (
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="form-control mt-2"
              />
            )}
            <h5 className="text-center pt-2">{`${userProfile?.firstName} ${userProfile?.lastName}`}</h5>
          </div>
          <div className="col-lg-10 col-md-10">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="mb-5">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">First Name</label>
                    {isEdit === false ? (
                      <p>{userProfile.firstName}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="First Name"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="firstname" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">Last Name</label>
                    {isEdit === false ? (
                      <p>{userProfile.lastName}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Last Name"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="lastname" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">Email</label>
                    {isEdit === false ? (
                      <p>{userProfile.email}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="email" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">Mobile Number</label>
                    {isEdit === false ? (
                      <p>{userProfile.mobileNo}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="phonenumber"
                          id="phonenumber"
                          placeholder="Mobile Number"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="phonenumber" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">Zip Code</label>
                    {isEdit === false ? (
                      <p>{userProfile.zipCode}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          placeholder="Postal Code"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="zipcode" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">City</label>
                    {isEdit === false ? (
                      <p>{userProfile.city}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="city"
                          id="city"
                          placeholder="City"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="city" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">State</label>
                    {isEdit === false ? (
                      <p>{userProfile.state}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="state"
                          id="state"
                          placeholder="State"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="state" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">School District</label>
                    {isEdit === false ? (
                      <p>{userProfile.schoolDistrict}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="schooldistrict"
                          id="schooldistrict"
                          placeholder="School District"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="schooldistrict" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <label className="form_label">School Name</label>
                    {isEdit === false ? (
                      <p>{userProfile.schoolName}</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="schoolname"
                          id="schoolname"
                          placeholder="School Name"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="schoolname" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {isEdit && (
                  <button type="submit" className="btn btn-primary mt-3">
                    Save
                  </button>
                )}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
