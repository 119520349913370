import React from "react";
import "./our-goals.css";

const OurGoals = () => {
  return (
    <div className="our-goal mt-5 mb-5">
      <div className="container">
        <h1 className="goal-heading mb-5">Our Goals</h1>
        <div className="row text-center g-4">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card">
              <img className="goal-image" src="/assets/question.png" alt="Questions"/>
              <div className="card-body">
                <h2 className="sub-heading">500+</h2>
                <p>Questions</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card">
            <img className="goal-image" src="/assets/score.png" alt="Score"/>
              <div className="card-body">
                <h2 className="sub-heading">4.5</h2>
                <p>AP Score</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card">
            <img className="goal-image" src="/assets/pass.png" alt="Pass Rate"/>
              <div className="card-body">
                <h2 className="sub-heading">100%</h2>
                <p>AP Pass Rate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGoals;
