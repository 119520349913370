import React from "react";
import './page-footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-section text-light">
        <div className="container pb-3">
          <div className="row gy-1 gx-1 py-4 gapping">
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* <h2 className="footer-headings">APScholars</h2> */}
              <img className="footer-image" src="/assets/APScholar.png"/>
              <div>
              <p className="paragraph-footer">We devised a unique methodology for teaching math & computer science which is based on problem solving leading to solid understanding of fundamentals.</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <h2 className="footer-headings">Courses</h2>
              <div>
                <ul className="d-inline-block">
                <li><Link className="link-btn" to="/enrollment/649186453b45fa5472f82eda">SAT Math</Link></li>
                {/* <li>AP CS Principles</li> */}
                <li><Link className="link-btn" to="/enrollment/649187353b45fa5472f82edc">AP CS A</Link></li>
                {/* <li>Machine Learning</li>
                <li>Artificial Intelligence</li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <h2 className="footer-headings">About Us</h2>
              <div>
                <ul className="d-inline-block">
                  <li>Terms and Conditions</li>
                  <li>Privacy Ppolicy</li>
                  <li>Help Center</li>
                  <li><Link className="link-btn" to="/about-us">About Us</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <h2 className="footer-headings">Contact</h2>
              <div>
                <ul className="d-inline-block">
                  <li>(346) 241 6949</li>
                  <li>arjun@apscholars.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer;