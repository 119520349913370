import React, { useState, useEffect } from "react";
import "./page-navbar.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/reducers/reducer";

const PageNavbar = () => {
  const { user, isLogin } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [colorChange, setColorchange] = useState(false);
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };
  const navigateToRegister = () => {
    navigate("/signup");
  };
  

  const logout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  //  // UseEffect to navigate to login page after logout
  //  useEffect(() => {
  //   if (!isLogin) {
  //     navigate("/login");
  //   }
  // }, [isLogin, navigate]);


  const changeNavbarColor = () => {
    if (window.scrollY >= 90) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <nav
        className={
          colorChange
            ? "navbar colorChange fixed-top navbar-expand-lg navbar-light "
            : "navbar navbar fixed-top navbar-expand-lg navbar-light "
        }
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="/assets/main-logo.png" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="page-nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="page-nav-item">
                <Link className="nav-link" aria-current="page" to="/about-us">
                  About
                </Link>
              </li>
              <li className="page-nav-item">
                <Link className="nav-link" aria-current="page" to="/contact-us">
                  Contact
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/about-us">
                  About Us
                </Link>
              </li> */}
            </ul>

            {!isLogin ? (
            <div>
              <button type="button" className="btn btn-outline-info me-2" onClick={navigateToLogin}>
                  Login
              </button>
              <button type="button" className="btn btn-success" onClick={navigateToRegister}>
                  SignUp
              </button>
            </div>
            ):(
              <div className="position-relative dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="icon-container">
                {user?.data?.user
                        ?  <img src={user.data.user.profileImageURL} alt="userImage" />
                        :  <img src="/assets/useravatar.png" alt="userImage" />
                        }
                  <div className="status-circle"></div>
                </div>
                <span className="username">
                      {user?.data?.user
                        ? `${user.data.user.firstName} ${user.data.user.lastName}`
                        : "Guest"}{" "}
                      <span className="dropdown-arrow"></span>
                    </span>
              </a>

              <ul className="dropdown-menu" aria-labelledby="userDropdown">
                <li>
                  <Link className="dropdown-item" to="/my-profile">
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/my-courses">
                    My Courses
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" onClick={logout}>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
              )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default PageNavbar;
