import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FaUserAlt, FaListAlt } from "react-icons/fa";

const Sidebar = () => {
  return (
    <section className="component-sidebar">
      <div className="logo-image d-flex justify-content-center">
        <img src="/assets/logo.png" alt="logo.png"/>
      </div>
      <div className="component-sidebar-nav">
        <ul>
          <li>
            <Link to="/dashboard">
              <div className="component-sidebar-nav-icon">
                <MdDashboard />
              </div>
              <div>Dashboard</div>
            </Link>
          </li>

          <li>
            <Link to="/my-profile">
              <div className="component-sidebar-nav-icon">
                <FaUserAlt />
              </div>
              <div>Profile</div>
            </Link>
          </li>

          <li>
            <Link to="/my-courses">
              <div className="component-sidebar-nav-icon">
                <FaListAlt />
              </div>
              <div>My Courses</div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Sidebar;
