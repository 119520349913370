import React from "react";

const ReferencesSheet = () => {
  return (
    <>
      <div className="row p-5">
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/1.svg" alt="image1" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/2.svg" alt="image2" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/3.svg" alt="image3" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/4.svg" alt="image4" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/5.svg" alt="image5" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/6.svg" alt="image6" />
        </div>
      </div>

      <div className="row pt-2 ps-5 pe-5">
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/7.svg" alt="image7" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/8.svg" alt="image8" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/9.svg" alt="image9" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/10.svg" alt="image10" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img src="/assets/referncesSheet/11.svg" alt="image11" />
        </div>
      </div>

      <div className="row p-5">
        <p>The number of degrees of arc in a circle is 360.</p>
        <p>The number of radians of arc in a circle is 2pi.</p>
        <p>The sum of the measures in degrees of the angles of a triangle is 180.</p>
      </div>
    </>
  );
};

export default ReferencesSheet;
