import React, { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../../redux/apis/rtk";
import { updateUser } from "../../../redux/reducers/reducer";
import Loader from "../../../shared/loader";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format!").required("Required!"),
  password: Yup.string().required("Required!"),
});

const LoginForm = ({ onLoginSuccess }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading, data, isSuccess, isError, error }] =
    useLoginMutation();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem("data", data.data.token);
    }
    if (isSuccess) {
      dispatch(updateUser(data));
      // Notify the parent component (PaymnentModal) about the login success
      if (onLoginSuccess) {
        onLoginSuccess();
      }
    }
  }, [dispatch, data, isSuccess, onLoginSuccess]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const formData = new FormData();
        formData.append("Email", values.email);
        formData.append("Password", values.password);
        await login(formData).unwrap();
      } catch (err) {
        console.log(err);
      }
    },
    [login]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.data.data || "Something Went Wrong!");
    }
  }, [isError, error]);

  return (
    <>
      <div className="p-sm-3">
        <div className="row">
          <div className="form_content col-lg-12 col-md-12">
            <h3>Login To APScholars</h3>
            <p>Please enter your login credentials below</p>
          </div>
        </div>
        {/* form-start */}
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="">
                <Field
                  className="form-input placeholder"
                  type="text"
                  name="email"
                  id="name"
                  placeholder="Email Address"
                />
                <div className="text-danger text-start">
                  <ErrorMessage name="email" />
                </div>
                <div className="auth-passwordfield">
                  <Field
                    className="form-input placeholder"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                  />
                  <img
                    className="eyeicon"
                    src={
                      showPassword
                        ? "/assets/eyeopen.svg"
                        : "/assets/eyeclose.svg"
                    }
                    alt="Toggle password visibility"
                    onClick={handlePasswordVisibility}
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="password" />
                  </div>
                </div>

                <div className="row">
                  <div className="content col-sm">
                    <p>
                      Don’t have an account
                      <Link to="/signup">
                        <span className="login_span ms-1">Sign Up</span>
                      </Link>
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <button
                      className="btn form_btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        {/* form-end */}
      </div>

      <Loader loading={isLoading} />
    </>
  );
};

export default LoginForm;
