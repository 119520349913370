import React from "react";
import './page-video.css';

const PageVideo = () => {
    return (
        <div className="page-video">
            <div className="container video-frame">
            <div className="d-flex align-items-center">
            <iframe className="video_play mt-5 mb-5" src="https://www.youtube.com/embed/XX6pLwnEIOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            </div>
        </div>
    )
}

export default PageVideo;