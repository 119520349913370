// import React from "react";
// import "./sat-quiz-details.css";
// import { useNavigate, useLocation, useParams } from "react-router-dom";

// const QuizDetails = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const resultData = location.state?.resultData;
//   const { testId, courseId } = useParams();

//   console.log("check result", resultData)
//   const navigateToAnswerDetails = (moduleIndex, questionIndex) => {
//     const question = resultData[moduleIndex].quizResultChoices[questionIndex];
//     navigate(`/course-details/${courseId}/${testId}/answerdetail`, { state: { question, questionIndex  } });
//   };

//   return (
//     <div className="padding">
//       <div className="quiz-container-sat result p-5 mt-5">
//         <div className="text-center">
//           <h2>Check Your Result</h2>
//           <p>Congratulations! You have complete the Test</p>
//           <p>
//             Below is your result summary, You can click on question number to
//             see the details.
//           </p>
//         </div>

//         <div className="box-answer">
//           <div className="d-flex justify-content-between">
//             <h2>Section 1 : Math</h2>
//             <div>
//               <span className="unanswered-question-box"></span>
//               <p className="text-inline">Un-attempt</p>
//             </div>
//           </div>
//           {resultData &&
//             resultData.map((module, moduleIndex) => (
//               <div key={moduleIndex}>
//                 <h3>
//                   <bold>Module {moduleIndex + 1}</bold>
//                 </h3>
//                 <div className="result-answer-box">
//                   {module.quizResultChoices.map((question, questionIndex) => (
//                     <div
//                       key={questionIndex}
//                       className={`answer-box-item me-3 ${
//                         question.isUserAnswerCorrect
//                           ? "answer-correct-box"
//                           : "answer-wrong-box"
//                       }`}
//                       onClick={() =>
//                         navigateToAnswerDetails(moduleIndex, questionIndex)
//                       }
//                     >
//                       {questionIndex + 1}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QuizDetails;

import React from "react";
import "./sat-quiz-details.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const QuizDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resultData = location.state?.resultData;
  const { testId, courseId } = useParams();

  console.log("check result", resultData)
  const navigateToAnswerDetails = (moduleIndex, questionIndex) => {
    const question = resultData[moduleIndex].quizResultChoices[questionIndex];
    navigate(`/course-details/${courseId}/${testId}/answerdetail`, { state: { question, questionIndex } });
  };

  return (
    <div className="padding">
      <div className="quiz-container-sat result p-5 mt-5">
        <div className="text-center">
          <h2>Check Your Result</h2>
          <p>Congratulations! You have completed the Test</p>
          <p>
            Below is your result summary. You can click on a question number to
            see the details.
          </p>
        </div>

        <div className="box-answer">
          <div className="d-flex justify-content-between">
            <h2>Section 1: Math</h2>
            <div>
              <span className="unanswered-question-box"></span>
              <p className="text-inline">Un-attempt</p>
            </div>
          </div>
          {resultData &&
            resultData.map((module, moduleIndex) => (
              <div key={moduleIndex}>
                <h3>
                  <bold>Module {moduleIndex + 1}</bold>
                </h3>
                <div className="result-answer-box">
                  {module.quizResultChoices.map((question, questionIndex) => (
                    <div
                      key={questionIndex}
                      className={`answer-box-item me-3 mb-2 ${
                        question.isUserAnswerCorrect === false
                          ? "answer-unattempted-box"
                          : question.isUserAnswerCorrect
                          ? "answer-correct-box"
                          : "answer-wrong-box"
                      }`}
                      onClick={() =>
                        navigateToAnswerDetails(moduleIndex, questionIndex)
                      }
                    >
                      {questionIndex + 1}
                    </div>
                  ))}

                  
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default QuizDetails;
