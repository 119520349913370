import React, { useState, useEffect } from "react";
import "./answer-details.css";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import QuizVideoModal from "../../chapter-quiz/quiz-video-modal";

const AnswerDetail = () => {
  const location = useLocation();
  const question = location.state?.question;
  const questionIndex = location.state?.questionIndex;
  const [isUserAnswerCorrect, setIsUserAnswerCorrect] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    // Compare userChoiceId with correctChoiceId to determine correctness
    if (question.userChoiceId === question.correctChoiceId) {
      // If the user's choice is correct, set isUserAnswerCorrect to true
      setIsUserAnswerCorrect(true);
    } else {
      // If the user's choice is incorrect, set isUserAnswerCorrect to false
      setIsUserAnswerCorrect(false);
    }
  }, [question.userChoiceId, question.correctChoiceId]);
  console.log("see answer detail", question);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="padding">
      <div className="sat-quiz-container mt-5">
        <div className="row">
          <div className="question-section col-lg-6">
            <div className="pt-5 pb-5">
              <div className="text-center">
                {question.questionDto.scenarioImageUrl && (
                  <img
                    src={question.questionDto.scenarioImageUrl}
                    alt="Scenario"
                    className="scenarioImage"
                  />
                )}
              </div>

              {question.questionDto.scenario1 ? (
                question.questionDto.scenario1.includes("\\(") ? (
                  <MathJaxContext input="tex">
                    <MathJax>
                      {ReactHtmlParser(question.questionDto.scenario1)}
                    </MathJax>
                  </MathJaxContext>
                ) : (
                  ReactHtmlParser(question.questionDto.scenario1)
                )
              ) : null}
              <br />
              <p className="description text-center">
                {ReactHtmlParser(question.questionDto.scenario2)}
              </p>
            </div>
          </div>
          <div className="col-lg-6 answer-section ps-4 pt-5 pb-5">
            <div className="question-number">
              <p className="q-number">{questionIndex + 1}</p>
            </div>
            <div className="pt-3">
              <strong>{question.questionDto.question.text}</strong>
            </div>

            {question.questionDto.type === "MCQ" ? (
              <div className="mcq-answer-section mt-4">
                {question.questionDto.choices.map((choice, index) => (
                  <div
                    key={index}
                    className={`mcq-choice ${
                      question.isQuestionAttempted
                        ? question.userChoiceId === choice.choiceId
                          ? isUserAnswerCorrect
                            ? "correct-answer"
                            : "wrong-answer"
                          : ""
                        : choice.choiceId === question.correctChoiceId
                        ? "correct-answer"
                        : ""
                    }`}
                  >
                    <div className="mcq-choice-letter">
                      {String.fromCharCode(65 + index)}
                    </div>
                    {choice.content.includes("\\(") ? (
                      <MathJaxContext input="tex">
                        <MathJax>{choice.content}</MathJax>
                      </MathJaxContext>
                    ) : (
                      ReactHtmlParser(choice.content)
                    )}
                  </div>
                ))}
              </div>
            ) : question.questionDto.type === "FIB" ? (
              // Render FIB content
              <div className="fib-answer-section mt-4">
                <p>
                  <strong>Correct Answer:</strong> {question.answer}
                </p>
              </div>
            ) : null}

            <div className="correct-answer-is mt-3">
              {/* On button click, open the modal */}
              <button className="answer-is" onClick={openModal}>
                Watch Tutorial
              </button>
            </div>

            <div className="mt-3">
              <h3>Explaination</h3>
              <p className="explaination-text">
                {question.explanation.includes("\\(") ? (
                  <MathJaxContext input="tex">
                    <MathJax>{ReactHtmlParser(question.explanation)}</MathJax>
                  </MathJaxContext>
                ) : (
                  ReactHtmlParser(question.explanation)
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <QuizVideoModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default AnswerDetail;
