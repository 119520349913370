import React from "react";
import "./proven-result.css";

const ProvenResult = () => {
  return (
    <div className="proven-result mt-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="content">
              <h3 className="title-content">Proven Result</h3>
              <p className="proven-paragraph">
                Track record of remarkable improvement in student interest and
                success
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12">
            <div className="d-inline-flex">
              <img
                className="proven-result-img"
                src="/assets/student.png"
                alt="student"
              />
              <ul className="proven-list">
                <li className="py-3"><img className="me-3" src="/assets/tick.png" alt="tick"/>Improve Test Grades By Double Digits</li>
                <li className="py-3"><img className="me-3" src="/assets/tick.png" alt="tick"/>Improve AP score to 4/5</li>
                <li className="py-3"><img className="me-3" src="/assets/tick.png" alt="tick"/>Flexible Time, Access Anywhere</li>
                <li className="py-3"><img className="me-3" src="/assets/tick.png" alt="tick"/>Shine In College Application</li>
                <li className="py-3"><img className="me-3" src="/assets/tick.png" alt="tick"/>Gain wider insight into careers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvenResult;
