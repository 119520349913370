import React from "react";
import "./contact-form.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const initialValues = {
  username: "",
  email: "",
  number: "",
  grade: "",
  message: "",
};
const onSubmit = () => {};
const validationSchema = Yup.object({
  username: Yup.string().required("username required!"),
  email: Yup.string()
    .email("Invalid email format!")
    .required("Email is required!"),
  number: Yup.string().required("Mobile number is required!"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  return (
    <section className="contact-card mb-5">
      <div className="container contact-details">
        <div className="py-5">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 left_side">
              {/* <div>
                <h4 className="mt-5 contact-title">APScholars</h4>
              </div>
              <div className="text-start ps-3">
                <h5>Email Us</h5>
                <p>sid@apscholars.us</p>
              </div>
              <div className="text-start ps-3">
                <h5>Phone Number</h5>
                <p>(346) 241 6949</p>
              </div> */}
              <img src="/assets/contact.png" alt="imagecontact"/>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 right_side">
              <h2>Quick Contact</h2>
              <p>
                Please fill the information below and we will get back to you
                shortly.
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="row">
                    <div className="col-6">
                      <Field
                        className="contact-form-input placeholder"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your Name"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                    <div className="col-6">
                      <Field
                        className="contact-form-input placeholder"
                        type="text"
                        name="email"
                        id="name"
                        placeholder="Email"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                      <div className="col-6">
                      <Field
                    className="contact-form-input placeholder"
                    type="number"
                    name="phoneNumber"
                    id="name"
                    placeholder="Mobile Number"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="phoneNumber" />
                  </div>
                      </div>
                      <div className="col-6">
                      <Field
                    className="contact-form-input placeholder"
                    type="text"
                    name="grade"
                    id="name"
                    placeholder="Grade"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="grade" />
                  </div>
</div>
                  </div>
                  
                  <Field
                    className="contact-form-input placeholder textarea"
                    id="name"
                    as="textarea"
                    rows="4"
                    type="text"
                    name="message"
                    placeholder="Your Message"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="message" />
                  </div>
                </Form>
              </Formik>
              <button className="contact_btn mt-4">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
