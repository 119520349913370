import React from "react";
import "./what-we-deliver.css";

const WhatWeDeliver = () => {
  return (
    <div className="deliver mb-5 mt-5">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
            <div className="">
              <h2 className="deliver-heading">What we deliver</h2>
              <p className="deliver-paragraph">At AP Scholars, we deliver a robust learning experience tailored to empower students in math and computer science</p>
            </div>
          </div>
          <div
            className="col-lg-6 order-1 order-lg-2 img"
            style={{ backgroundImage: `url(${"/assets/deliver.png"})` }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDeliver;
