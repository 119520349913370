import React from "react";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import * as BsIcons from 'react-icons/bs';
import * as TbIcons from 'react-icons/tb';
import { useParams } from "react-router-dom";

const SidebarData = [

{
    
    title: "SAT Practice 1",
    // path: "/auth/course",
    icon: <FaIcons.FaJava size={30}/>,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
    {
        title: "Introduction",
        path: "/course-details/:courseId/chapter",
        icon: <TbIcons.TbListDetails size={20}/>,
        cName: "sub-nav",
    },
    {
        title: "Quiz",
        path: "/course-details/:courseId/sat",
        icon: <TbIcons.TbListDetails size={20}/>,
        cName: "sub-nav",
    },
    // {
    //     title: "Chapter Video",
    //     path: "/course-details/video",
    //     icon: <AiIcons.AiFillPlayCircle size={20}/>,
    //     cName: "sub-nav",
    // },
    // {
    //     title: "FIB Quiz",
    //     path: "/course-details/fib-quiz/:id",
    //     icon: <IoIcons.IoIosPaper size={20}/>,
    //     cName: "sub-nav",
    // },
    // {
    //     title: "Quiz",
    //     path: "/course-details/quiz/:id",
    //     icon: <MdIcons.MdQuiz size={20}/>,
    // },
    // {
    //     title: "Programming",
    //     path: "/course-details/task",
    //     icon: <BsIcons.BsCodeSlash size={20}/>,
    // },
    ],
},
// {
//     title: "Boolean Algebra",
//     path: "/auth/course",
//     icon: <IoIcons.IoIosPaper size={20}/>,
//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,

//     subNav: [
//     {
//         title: "Introduction",
//         path: "https://www.youtube.com/embed/HQX_1DCJ2l8",
//         icon: <IoIcons.IoIosPerson />,
//         cName: "sub-nav",
//     },
//     {
//         title: "MCQ",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//         cName: "sub-nav",
//     },
//     {
//         title: "FRQ Pattern",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//     },
//     ],
// },
// {
//     title: "Loops/Iteration",
//     path: "/auth/course",
//     icon: <FaIcons.FaEnvelopeOpenText size={20}/>,

//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,

//     subNav: [
//     {
//         title: "Introduction",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//     },
//     {
//         title: "FRQ Patterns",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//     },
//     ],
// },
// {
//     title: "Arrays",
//     path: "/auth/course",
//     icon: <FaIcons.FaEnvelopeOpenText size={20}/>,

//     iconClosed: <RiIcons.RiArrowDownSFill />,
//     iconOpened: <RiIcons.RiArrowUpSFill />,

//     subNav: [
//     {
//         title: "Introduction",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//     },
//     {
//         title: "FRQ Patterns",
//         path: "/auth/course",
//         icon: <IoIcons.IoIosPaper />,
//     },
//     ],
// }
];


export default SidebarData;