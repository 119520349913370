import React, {useEffect} from 'react';
import "../../App.css";
import Sidebar from "../sidebar/sidebar";
import Navbar from '../navbar/navbar';
import { useNavigate } from 'react-router-dom';

const DashboardLayout = ({Component}) =>{
    const userLoggedIn = true; 
    const navigate = useNavigate();
    useEffect(()=>{
        if(!userLoggedIn)
        navigate("/login");
    },[])
    return (<>
        <Navbar />
          <div className="main">
            <div className="sidebar">{<Sidebar />}</div>
            <div className="main-wrapper">
              <Component/>
            </div>
          </div>
    </>);
};

export default DashboardLayout;