import React, {useState, useEffect} from "react";
import "./otp-verification.style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const initialValues = {
  otpcode: "",
};
const onSubmit = () => {};
const validationSchema = Yup.object({
  otpcode: Yup.string().email("Invalid format!").required("Required!"),
});

const OTPVerfication = () => {

    const [timer, setTimer] = useState(20);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = () => {
    setTimer(20);
    // Add logic to handle resend functionality
  };


  return (
    <div className="forgot_bg">
      <Link to="/" className="link-button top-left">
        <img
          className="arrow_icon"
          src="/assets/back_line_arrow.svg"
          alt="back"
        />
        <span className="button-text">Go to Main Website</span>
      </Link>
      <div className="container forgot-container">
        <div className="row form_box">
          <div className="col-lg-6">
            <div className="left-side">
              <img
                className="login_img"
                src="/assets/login.png"
                alt="backcover"
              />
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="p-sm-3">
              <div className="row">
                <div className="form_content col-lg-12 col-md-12">
                  <h3>OTP Verification</h3>
                  <p>OTP Code send to your mobile number</p>
                </div>
              </div>
              {/* form-start */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form className="">
                      <Field
                        className="form-input input_placeholder"
                        type="text"
                        name="otpcode"
                        id="otpcode"
                        placeholder="Enter 4-digits OTP Code"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="otpcode" />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="row">
                <div className="content col-sm">
                  <p className="float-left">
                    Didn't get the code
                    <Link to="#" className="link-class">
                      <span className="forgot_span ms-1">send again</span>
                    </Link>
                  </p>
                </div>

                <div className="col-lg-12 col-md-12">
                  <button className="form_btn">Verified</button>
                </div>
              </div>
              {/* form-end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerfication;
