import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const QuizVideoModal = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "800px",
      height: "400px",
      padding: "0px",
      border: "10px",
      overflow: "none",
      borderRadius: "10px"
    },
  };

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onAfterOpen={props.onAfterOpen}
        onRequestClose={props.onRequestClose}
        style={customStyles}
      >
      <div className="quiz-container">
        <ReactPlayer
          className="player"
          playing={true}
          url = "https://www.youtube.com/watch?v=osX3gNkRlK0"
          width="100%"
          height="100%"
          controls = {true}
        />
        </div>
      </Modal>
    </div>
  );
};

export default QuizVideoModal;