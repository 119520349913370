// import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { useState } from "react";

// export default function CheckoutForm({ onPaymentMethodCreated }) {
//   const stripe = useStripe();
//   const elements = useElements();

//   const [message, setMessage] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     setIsProcessing(true);

//     // Create payment method
//     const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(PaymentElement),
//     });

//     if (paymentMethodError) {
//       setMessage(paymentMethodError.message);
//       setIsProcessing(false);
//       return;
//     }

//     // Pass the payment method ID to the parent component (Payment)
//     onPaymentMethodCreated(paymentMethod.id);

//     // Confirm payment
//     const { error } = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         payment_method: paymentMethod.id,
//         return_url: `${window.location.origin}/completion`,
//       },
//     });

//     if (error) {
//       setMessage(error.message);
//     } else {
//       setMessage("An unexpected error occurred.");
//     }

//     setIsProcessing(false);
//   };

//   return (
//     <form id="payment-form" onSubmit={handleSubmit}>
//       <PaymentElement id="payment-element" />
//       <button disabled={isProcessing || !stripe || !elements} id="submit">
//         <span id="button-text">
//           {isProcessing ? "Processing ..." : "Pay now"}
//         </span>
//       </button>
//       {message && <div id="payment-message">{message}</div>}
//     </form>
//   );
// }


// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import "./checkoutForm.css";

// const CheckoutForm = () => {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [errorMessage, setErrorMessage] = useState(null);

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         if (!stripe || !elements) {
//             return;
//         }

//         const amount = 1050;
//         const formData = new URLSearchParams();
//         formData.append("Amount", amount.toString());

//         try {
//             const response = await fetch('https://apscholars-api.azurewebsites.net/api/Course/payment-intent', {
//                 method: "POST",
//                 body: formData,
//                 headers: {
//                     "Content-Type": "application/x-www-form-urlencoded",
//                 },
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const { clientSecret } = await response.json();

//             const cardElement = elements.getElement(CardElement);

//             const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
//                 payment_method: {
//                     card: cardElement,
//                     billing_details: {
//                         name: 'Test User',
//                     },
//                 },
//             });

//             if (error) {
//                 setErrorMessage(error.message);
//             } else {
//                 console.log('Payment Intent:', paymentIntent);
//             }
//         } catch (error) {
//             setErrorMessage(error.message);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <CardElement />
//             <button className='payment-button' type="submit" disabled={!stripe}>Pay</button>
//             {errorMessage && <div>{errorMessage}</div>}
//         </form>
//     );
// };

// export default CheckoutForm;


// import React, { useState } from 'react';
// import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import "./checkoutForm.css";

// const CheckoutForm = () => {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [errorMessage, setErrorMessage] = useState(null);

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         if (!stripe || !elements) {
//             return;
//         }

//         const amount = 1050;
//         const formData = new URLSearchParams();
//         formData.append("Amount", amount.toString());

//         try {
//             const response = await fetch('https://apscholars-api.azurewebsites.net/api/Course/payment-intent', {
//                 method: "POST",
//                 body: formData,
//                 headers: {
//                     "Content-Type": "application/x-www-form-urlencoded",
//                 },
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const { clientSecret } = await response.json();

//             const { error } = await stripe.confirmPayment({
//                 elements,
//                 confirmParams: {
//                     // Make sure to pass the correct billing details
//                     payment_method_data: {
//                         billing_details: {
//                             name: 'Test User',
//                         },
//                     },
//                 },
//                 redirect: 'if_required'
//             });

//             if (error) {
//                 setErrorMessage(error.message);
//             } else {
//                 console.log('Payment succeeded');
//             }
//         } catch (error) {
//             setErrorMessage(error.message);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <PaymentElement id="payment-element" />
//             <button className='payment-button' type="submit" disabled={!stripe}>Pay</button>
//             {errorMessage && <div className="error-message">{errorMessage}</div>}
//         </form>
//     );
// };

// export default CheckoutForm;


// This One is Working Fine
// import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { useState } from "react";

// export default function CheckoutForm({ onPaymentMethodCreated }) {
//   const stripe = useStripe();
//   const elements = useElements();

//   const [message, setMessage] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     setIsProcessing(true);

//     try {
//       // Confirm payment directly with PaymentElement
//       const { error, paymentIntent } = await stripe.confirmPayment({
//         elements,
//         confirmParams: {
//           return_url: `${window.location.origin}/completion`,
//         },
//       });

//       if (error) {
//         setMessage(error.message);
//       } else {
//         setMessage("Payment successful!");
//       }
//     } catch (error) {
//       setMessage("An unexpected error occurred.");
//     }

//     setIsProcessing(false);
//   };

//   return (
//     <form id="payment-form" onSubmit={handleSubmit}>
//       <PaymentElement id="payment-element" />
//       <button disabled={isProcessing || !stripe || !elements} id="submit">
//         <span id="button-text">
//           {isProcessing ? "Processing ..." : "Pay now"}
//         </span>
//       </button>
//       {message && <div id="payment-message">{message}</div>}
//     </form>
//   );
// }

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";

export default function CheckoutForm({ onPaymentMethodCreated, onPaymentSuccess  }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setIsProcessing(true);

  //   try {
  //     // Confirm payment directly with PaymentElement
  //     const { error, paymentIntent } = await stripe.confirmPayment({
  //       elements,
  //       // confirmParams: {
  //       //   return_url: `${window.location.origin}/completion`,
  //       // },
  //     });
  //     if (paymentIntent) {
  //       console.log("PaymentIntent ID:", paymentIntent.id);
  //     } else {
  //       console.log("No PaymentIntent created.");
  //     }

  //     if (error) {
  //       setMessage(error.message);
  //       console.log("PaymentIntent ID error case:", paymentIntent.id);
  //     } else {
  //       setMessage("Payment successful!");
  //       console.log("PaymentIntent ID:", paymentIntent.id);
  //       if (onPaymentMethodCreated && paymentIntent.payment_method) {
  //         onPaymentMethodCreated(paymentIntent.payment_method);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error during payment submission:", error);
  //     setMessage("An unexpected error occurred.");
  //   }

  //   setIsProcessing(false);
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsProcessing(true);
  
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Optional: set the return URL here if you want automatic redirection
          return_url: `${window.location.origin}/completion`,
        },
        // This option prevents automatic redirection
        redirect: 'if_required',
      });
  
      if (paymentIntent) {
        console.log("PaymentIntent ID:", paymentIntent.id);
      } else {
        console.log("No PaymentIntent created.");
      }
  
      if (error) {
        setMessage(error.message);
        console.log("PaymentIntent ID error case:", paymentIntent?.id);
      } else {
        setMessage("Payment successful!");
        console.log("PaymentIntent ID:", paymentIntent.id);
        onPaymentSuccess(paymentIntent.id);
        if (onPaymentMethodCreated && paymentIntent.payment_method) {
          onPaymentMethodCreated(paymentIntent.payment_method);
        }
      }
    } catch (error) {
      console.error("Error during payment submission:", error);
      setMessage("An unexpected error occurred.");
    }
  
    setIsProcessing(false);
  };


  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit" className="payment-button">
        <span id="button-text">
          {isProcessing ? "Processing ..." : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message" className="error-message">{message}</div>}
    </form>
  );
}
