import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./my-courses.css";
import { useMyCoursesQuery } from "../../redux/apis/rtk";
import Loader from "../../shared/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MyCourses = () => {
  const { user, isLogin } = useSelector((state) => state.users);
  const { data: coursesData = [], isLoading } = useMyCoursesQuery();
  const navigate = useNavigate();

  console.log("hello", coursesData)
  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    // <>
    //   {isLoading ? (
    //     <div className="loading-message"></div>
    //   ) : (
    //     <div className="row g-4">
    //       {coursesData.length > 0 ? (
    //         coursesData.map((data) => {
    //           return (
    //             <div className="col-lg-4 col-md-4 col-sm-12">
    //               <Link
    //                 className="link-class"
    //                 to={{
    //                   pathname: `/course-details/${data.id}/chapter`,
    //                   state: { courseId: data.id },
    //                 }}
    //               >
    //                 <div className="course-card" key={data.id}>
    //                   <div className="pt-5 ps-4">
    //                     <h4 className="card-title">{data.name}</h4>
    //                     <p className="card-text">{data.description}</p>
    //                     <div className="d-flex justify-content-between pe-3"></div>
    //                   </div>
    //                 </div>
    //               </Link>
    //             </div>
    //           );
    //         })
    //       ) : (
    //         <div className="center-page">
    //           <div className="text-centerpage">
    //             <p>No Course register yet.</p>
    //           </div>
    //           <p>
    //             Click the link to register your course.{" "}
    //             <Link to="/">Register Now</Link>
    //           </p>
    //         </div>
    //       )}
    //     </div>
    //   )}
    //   <Loader loading={isLoading} />
    // </>

    <>
    {isLoading ? (
      <div className="loading-message"></div>
    ) : (
      <div className="row g-4">
        {coursesData.length > 0 ? (
          coursesData.map((data) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12" key={data.id}>
                <Link
                  className="link-class"
                  to={{
                    pathname: `/course-details/${data.id}/chapter`,
                    state: { courseId: data.id },
                  }}
                >
                  <div className="card-container">
                    {/* Card Banner */}
                    <div
                      className="card-banner"
                      style={{
                        backgroundImage: `url(${data.courseImageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "150px",
                      }}
                    >
                      <span className="badge">Bestseller</span>
                    </div>
                    {/* Card Content */}
                    <div className="card-content">
                      <h2 className="title">{data.name}</h2>
                      <p className="card-text">{data.description}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <div className="center-page">
            <div className="text-centerpage">
              <p>No Course registered yet.</p>
            </div>
            <p>
              Click the link to register your course.{" "}
              <Link to="/">Register Now</Link>
            </p>
          </div>
        )}
      </div>
    )}
    <Loader loading={isLoading} />
  </>
  
  );
};

export default MyCourses;
