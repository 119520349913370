import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./course-navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/reducers/reducer";

const CourseNavbar = (props) => {
  const { user, isLogin } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const collapse= props.collapse;

  const logout = () => {
    console.log("Logging out user...");
    dispatch(logoutUser());
    console.log("Logging out user2...");
    navigate("/login");
  };

   // UseEffect to navigate to login page after logout
   useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);

    return (
      <div className={collapse ? "course-navbar2" : "course-navbar"}>
          <div className="courses-navbar">
        <nav className="navbar">
          <div className="course-navbar-brand">
            <div className="header-course">
              <h3>Chapter Overview</h3>
            </div>
          </div>
          <div className="navbar navbar-expand-md">
          <ul className="ul navbar-nav">
              <li className="nav-item">
                <NavLink className="header-course" aria-current="page" to="/dashboard">
                  Home
                </NavLink>
              </li>
            </ul>
             <ul className="navbar-nav mb-2 mb-lg-0 d-row">
            {isLogin && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="#">
                    <div className="component-navbar-user-image">
                    {user?.data?.user
                        ?  <img src={user.data.user.profileImageURL} alt="userImage" />
                        :  <img src="/assets/useravatar.png" alt="userImage" />
                        }
                      <div className="status-circle"></div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="username">
                      {user?.data?.user
                        ? `${user.data.user.firstName} ${user.data.user.lastName}`
                        : "Guest"}{" "}
                      <span className="dropdown-arrow"></span>
                    </span>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item" to="/my-profile">
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Settings
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={logout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
          </div>
        </nav>
        </div>
      </div>
    );
}

export default CourseNavbar;
