import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const API = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://apscholars-api.azurewebsites.net/",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("data");
            headers.set("mode", `no-cors`);
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
        // Custom fetch function to add 'no-cors' mode
        // fetchFn: (args, api, extraOptions) =>
        //     fetch(args, { ...extraOptions, mode: "no-cors" })
    }),
    endpoints: builder => ({
        login: builder.mutation({
            query: formData => ({
                url: `api/Auth/login`,
                method: "POST",
                body: formData,
            }),
        }),
        signUp: builder.mutation({
            query: formData => ({
                url: "api/Auth/register",
                method: "POST",
                body: formData,
            })
        }),
        
        allCourses: builder.query({ query: () => "/api/Course", mode : 'no-cors' }),
        getUser: builder.query({ query: () => "/api/User" }),
        myCourses: builder.query({ query: () => "/api/Course/user" }),
        quizSidebar: builder.query({ query: (courseId) => `/api/Course/side-menu/course-id/${courseId}`}),
        SATQuiz: builder.query({ query: (testId) => `api/Module/course-content-id/${testId}`}),
        //SATModule: builder.query({ query: () => `/api/Quiz/course-id/65eefebe668c6d4d8f5136a7/module-id/65cee4471f45713d12612f60` }),
        SATModule: builder.query({ query: (moduleId) => `/api/Quiz/module-id/${moduleId}` }),
        quizScore: builder.query({ query: (testId) => `api/Quiz/quiz-result/course-content-id/${testId}`}),
        saveResult: builder.mutation({
            query: jsonData  => ({
                url: "api/Quiz/save-result",
                method: "POST",
                body: jsonData,
            })
        }),
        saveUser: builder.mutation({
            query: jsonData  => ({
                url: "api/User",
                method: "PUT",
                body: jsonData,
            })
        }),
        chapterQuiz: builder.query({ query: () => `/api/Quiz/course-id/6601204a47d35f818eaa2e38/type/MCQ` }),
        //chapterQuiz: builder.query({ query: (courseID, type) => `api/Quiz/course-id/${courseID}/type/${type}`}), 

        enrollmentCourse: builder.mutation({
            query: ({ courseId, paymentIntentId }) => ({
                url: `api/Course/${courseId}/paymentId/${paymentIntentId}`,
                method: "POST",
                // body: jsonData,
            }),
        }),
        

    })
})

export const { 
    useLoginMutation, 
    useSignUpMutation, 
    useAllCoursesQuery,
    useEnrollmentCourseMutation,
    useGetUserQuery,
    useMyCoursesQuery,
    useQuizSidebarQuery,
    useSATQuizQuery,
    useSATModuleQuery,
    useSaveResultMutation,
    useSaveUserMutation,
    useQuizScoreQuery,
    useChapterQuizQuery,
} = API;


// modules data:

// id : 65cc3c321f45713d12612f54
// name:  "Reading and Writing"


// id: 65cee4471f45713d12612f60
// name: "Math"