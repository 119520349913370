import React, { useCallback, useState, useEffect } from "react";
import "./signup.style.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { useDispatch } from "react-redux";
import { useSignUpMutation } from "../../../redux/apis/rtk";
//import { updateUser } from "../../../redux/reducers/reducer";
import Loader from "../../../shared/loader";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNo: "",
  password: "",
  cpassword: "",
};


const validationSchema = Yup.object({
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
  email: Yup.string().email("Invalid email format!").required("Required!"),
  mobileNo: Yup.string().required("Required!"),
  password: Yup.string().required("Required!"),
  cpassword: Yup.string()
    .required("Required!")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const SignUp = () => {

    let navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [signUp, { isLoading, isSuccess, isError, error }] = useSignUpMutation();
  
    const handlePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const handleCPasswordVisibility = () => {
      setShowCPassword(!showCPassword);
    };


    useEffect(() => {
        if (isSuccess) {
          navigate("/login");
        }
      }, [navigate, isSuccess]);
    
      const onSubmit = useCallback(
        async values => {
          try {
            const formData = new FormData();
            formData.append("FirstName", values.firstName);
            formData.append("LastName", values.lastName)
            formData.append("Email", values.email)
            formData.append("MobileNo", values.mobileNo)
            formData.append("Password", values.password)
            await signUp(formData).unwrap();
          } catch (err) {
            console.log(err);
          }
        },
        [signUp]
      );
    
      useEffect(() => {
        if (isError) {
          toast.error( error?.data.data || "Something Went Wrong!")
        }
      }, [isError, error])

      

  return (
    <>
    <div className="signup-bg">
      <Link to="/" className="link-button top-left">
        <img
          className="arrow_icon"
          src="/assets/back_line_arrow.svg"
          alt="back"
        />
        <span className="button-text">Go to Main Website</span>
      </Link>
      <div className="container signUp-container">
        <div className="row form_box">
          <div className="col-lg-6">
            <div className="left-side">
              <img
                className="login_img"
                src="/assets/login.png"
                alt="backcover"
              />
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="p-sm-3">
              <div className="row">
                <div className="form_content content col-lg-12 col-md-12">
                  <h3>Create Your Account</h3>
                  <p>Please fill the form below</p>
                </div>
              </div>
              {/* form-start */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              className="form-input input_placeholder"
                              type="text"
                              name="firstName"
                              id="FirstName"
                              placeholder="First Name"
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="firstName" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              className="form-input input_placeholder"
                              type="text"
                              name="lastName"
                              id="LastName"
                              placeholder="last Name"
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="LastName" />
                            </div>
                          </div>
                        </div>
                        <Field
                          className="form-input input_placeholder"
                          type="text"
                          name="email"
                          id="Email"
                          placeholder="Email Address"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="email" />
                        </div>

                        <Field
                          className="form-input input_placeholder"
                          type="number"
                          name="mobileNo"
                          id="MobileNo"
                          placeholder="Phone Number"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="mobileNo" />
                        </div>

                       {/* Password Field */}
                       <div className="auth-passwordfield">
                            <Field
                              className="form-input placeholder"
                              id="password"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                            />
                            <img
                              className="eyeicon"
                              src={
                                showPassword
                                  ? "/assets/eyeopen.svg"
                                  : "/assets/eyeclose.svg"
                              }
                              alt="Toggle password visibility"
                              onClick={handlePasswordVisibility}
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="password" />
                            </div>
                          </div>
                          <div className="auth-passwordfield">
                            <Field
                              className="form-input placeholder"
                              id="cpassword"
                              type={showCPassword ? "text" : "password"}
                              name="cpassword"
                              placeholder="Confirm Password"
                            />
                            <img
                              className="eyeicon"
                              src={
                                showCPassword
                                  ? "/assets/eyeopen.svg"
                                  : "/assets/eyeclose.svg"
                              }
                              alt="Toggle password visibility"
                              onClick={handleCPasswordVisibility}
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="cpassword" />
                            </div>
                          </div>


                        <div className="row">
                          <div className="content col-sm">
                            <p className="login-paragraph">
                              Already have an account
                              <Link to="/login">
                                <span className="login_span ms-1">Sign In</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <button
                              className="form_btn"
                              type="submit"
                              // disabled={isSubmitting}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {/* form-end */}
            </div>
          </div>
        </div>
      </div>
    </div>

     <Loader loading={isLoading} />
    </>
  );
};

export default SignUp;
