// import {usersReducer} from "../reducers/reducer";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { API } from "../apis/rtk";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = combineReducers({
//   users: persistReducer(persistConfig, usersReducer),
//   [API.reducerPath]: API.reducer,
// });

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(API.middleware),
// });

// export const persistor = persistStore(store);

import { usersReducer, coursesReducer } from "../reducers/reducer"; 
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { API } from "../apis/rtk";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  users: persistReducer(persistConfig, usersReducer), 
  courses: persistReducer(persistConfig, coursesReducer),
  [API.reducerPath]: API.reducer, 
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(API.middleware), 
});

export const persistor = persistStore(store);
