import React from "react";
import "./about-banner.css";

const AboutBanner = () => {
  return (
    <div className="aboutbanner">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
            <div className="">
              <h2 className="about-heading">ABOUT US</h2>
              <p className="about-paragraph">We devised a unique methodology for teaching SAT Math & 
              computer science which is based on interactive problem solving and active feedback leading to
              solid understanding of fundamentals</p>
            </div>
          </div>
          <div
            className="col-lg-6 order-1 order-lg-2 img"
            style={{ backgroundImage: `url(${"/assets/aboutus.png"})` }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
