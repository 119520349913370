import React from "react";
import "./our-courses.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useAllCoursesQuery } from "../../../redux/apis/rtk";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, marginRight: "26px", zIndex: 1 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, marginLeft: "26px", zIndex: 1 }}
      onClick={onClick}
    />
  );
}

const OurCourses = () => {
  const { data: coursesData, isLoading } = useAllCoursesQuery();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      {isLoading ? (
        <div className="loading-message"></div>
      ) : (
        <div className="container pb-5 mb-3">
          <div className="text-center">
            <h1 className="course-heading">Our Courses</h1>
            {/* <p className="sub-paragrapgh">
              Develop your mind and skills by our intense track that covers all
              you need
            </p> */}
          </div>
          <div className="row g-3 mt-5 courses-C colgap">
            {coursesData.length > 0 ? (
              coursesData.slice(0, 3).map((data, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <Link
                        className="link-class"
                        to={`/enrollment/${data.id}`}
                      >
                        <div className="courses-card">
                          {/* <div>
                              <img
                               src={data.courseImageUrl}
                                className="card-img-top"
                                alt="courseImage"
                              />
                            </div> */}
                          <div
                            className="card-banner"
                            style={{
                              backgroundImage: `url(${data.courseImageUrl})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "150px",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                            }}
                          >
                            <span className="badge">Bestseller</span>
                          </div>
                          {/* <Slider {...settings}>
                            <div>
                              <iframe
                                title="video"
                                className="card-img-top"
                                src={data.courseImageUrl}
                              ></iframe>
                            </div>
                            <div>
                              <img
                               src={data.courseImageUrl}
                                className="card-img-top"
                                alt="courseImage"
                              />
                            </div>
                            <div>
                              <img
                                src="/assets/java.jpg"
                                className="card-img-top"
                                alt="courseImage"
                              />
                            </div>
                          </Slider> */}
                          <div className="card-body">
                            {/* <div className="d-flex justify-content-between"> */}
                            <div className="card-title-container">
                              <h3 className="card-main-title">{data.name}</h3>
                            </div>
                            {/* <div className="align-item-center">
                                <img
                                  className="star-icon"
                                  src="/assets/star.svg"
                                  alt="rating"
                                />
                                <span className="reviews">(124)</span>
                              </div> */}
                            {/* </div> */}
                            <p className="card-text">
                              {data.description.slice(0, 50)}
                            </p>
                            {/* <div className="align-item-center">
                              <img
                                className="star-icon"
                                src="/assets/star.svg"
                                alt="rating"
                              />
                              <span className="reviews">(124)</span>
                            </div> */}
                            {/* <div className="d-flex justify-content-between">
                              <div className="text-left">
                                <img
                                  className="favorite"
                                  src="/assets/heart_fill.svg"
                                  alt="heart"
                                />
                              </div>
                              <div className="align-item-center">
                              <img
                                className="star-icon"
                                src="/assets/star.svg"
                                alt="rating"
                              />
                              <span className="reviews">(100+)</span>
                            </div>
                              <div>
                                <small className="starting-price me-2">
                                  Starting At
                                </small>
                                <span className="reviews">
                                  $ {data.pricing}
                                </span>
                              </div>
                            </div> */}
                            <div className="d-flex justify-content-between">
                              {/* Star and Rating Section */}
                              <div className="d-flex align-items-center">
                                <img
                                  className="star-icon me-1"
                                  src="/assets/star.svg"
                                  alt="rating"
                                />
                                <span className="reviews">(100+)</span>
                              </div>

                              {/* Pricing Section */}
                              <div>
                                <small className="starting-price me-2">
                                  Starting At
                                </small>
                                <span className="reviews text-right">
                                  $ {data.pricing}
                                </span>
                              </div>
                            </div>

                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="card-foter-content">
                                {data.noOfCourses} Questions
                              </p>
                              <p className="card-foter-content">
                                {data.noOfLesson} Videos
                              </p>
                              <p className="card-foter-content">
                                {data.duration}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div className="text-center">No courses available.</div>
            )}
          </div>

          {/* <div className="text-center mt-5">
            <button className="course-button mt-3">See More</button>
          </div> */}
        </div>
      )}
    </>
  );
};

export default OurCourses;
