import React from "react";
import "./course-overview.css";
import { Link } from "react-router-dom";
import SATPractice from "../../../shared/course-list.json";
import PaymnentModal from "../payment-modal/payment-modal";
import courseAPCSA from "../../../shared/course-list-APCSA.json";
import { useSelector } from "react-redux";

const CourseOverview = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const courses = useSelector((state) => state.courses);
  const courseId = courses.courseId; // Get courseId from the Redux store

  console.log("Courses state in enrollment:", courseId);

  let courseDescription = [];

  if (courseId === "649187353b45fa5472f82edc") {
    courseDescription = courseAPCSA;
  } else if (courseId === "649186453b45fa5472f82eda") {
    courseDescription = SATPractice;
  } else if (courseId === "6601204a47d35f818eaa2e38") {
    courseDescription = SATPractice;
  } else {
    console.log("Invalid course ID");
  }

  return (
    <div className="container mt-5 py-5">
      <div className="row g-4">
        <div className="col-lg-9 col-md-12">
          <section>
            {courseDescription.map((course) => (
              <div className="profileSection" key={course.id}>
                {/* about Instructor*/}
                {/* <div className="row">
                  <div className="col-lg-5 col-md-5">
                    <img
                      className="img-fluid profileimg rounded-3"
                      height="240px"
                      width="340px"
                      src="/assets/default_tutor.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <h3>{course.instructor}</h3>
                    <p className="paragraph">{course.instructorDescription}</p>
                  </div>
                </div> */}

                {/* about Course*/}
                <div className="about mt-5">
                  <h2 className="course-overview-headings">{course.title}</h2>
                  <p className="paragraph">{course.desription}</p>
                </div>
                {/* Whats included */}
                <div className="mt-5">
                  <h2 className="course-overview-headings">What's Included</h2>
                  <ul className="included_list">
                    {course.whatIsIncluded.map((item, index) => (
                      <li key={index} className="py-2">
                        <img
                          className="me-3 tick_icon"
                          src="/assets/tick.png"
                          alt="tick"
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Whats will you learn */}
                <div className="mt-5">
                  <h2 className="course-overview-headings">
                    What Will You Learn
                  </h2>
                  <ul className="included_list">
                    {course.whatYouWillLearn.map((item, index) => (
                      <li key={index} className="py-2">
                        <img
                          className="me-3 tick_icon"
                          src="/assets/tick.png"
                          alt="tick"
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* FAQs */}
                <div className="mt-5 about">
                  <h2 className="course-overview-headings">FAQs</h2>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    {course.faq.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <h2
                          className="accordion-header"
                          id={`flush-heading-${index}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${index}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse-${index}`}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse-${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`flush-heading-${index}`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">{item.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* rating and reviews */}
                <div className="mt-5">
                  <h2 className="course-overview-headings">Rating & Reviews</h2>
                </div>
                {/* rating with profile */}
                {course.reviews.map((review, index) => (
                  <div key={index} className="reviews">
                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="d-inline-flex">
                        {/* <img
                          className="reviewImg"
                          src="/assets/AsadWali.jpg"
                          alt="user"
                        /> */}
                        <div className="ms-4">
                          <h3 className="reviewer-name">{review.name}</h3>
                          {/* <p>⭐ {review.ratings}</p> */}
                        </div>
                      </div>
                      <p className="reviewdate">{review.date}</p>
                    </div>
                    <p className="review_para">{review.text}</p>
                  </div>
                ))}
                {/* <Link to="#" className="linkblog-button">
            View More Reviews
          </Link> */}
              </div>
            ))}
          </section>
        </div>

        <div className="col-lg-3 col-md-12">
          <>
            <section className="enrollment">
              {courseDescription.map((course) => (
                <>
                  <div>
                    <h3 className="app-head">{course.title}</h3>
                    {/* <p className="app-head">Cost : ${course.price}</p> */}
                    <h3 className="app-head">What's included:</h3>
                    <ul>
                      <li>
                        <img
                          className="enroll-img me-3"
                          src="/assets/calender.svg"
                          alt="calender"
                        />
                        {course.type}
                      </li>
                      <li>
                        <img
                          className="enroll-img me-3"
                          src="/assets/clock.svg"
                          alt="clock"
                        />
                        {course.testCount}
                      </li>
                      <li>
                        <img
                          className="enroll-img me-3"
                          src="/assets/clock.svg"
                          alt="clock"
                        />
                        {course.duration}
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      className="enroll_btn mb-3"
                      onClick={openModal}
                    >
                      <span class="button-text">Enroll</span>
                      <span class="button-price">${course.price}</span>
                    </button>

                    <button type="button" className="help_btn">
                      Help (346) 241 6949
                    </button>
                  </div>
                </>
              ))}
            </section>
            <PaymnentModal isOpen={modalIsOpen} onRequestClose={closeModal} />
          </>
        </div>
      </div>
    </div>
  );
};

export default CourseOverview;
