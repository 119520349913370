import React, { useState } from "react";
import "./add-refrence.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddReference = () => {

    const [isEdit, setEdit] = useState(false);

    const initialValues = {
      refrence1: "",
      refrence2: "",
      refrence3: "",
      refrence4: "",
      refrence5: "",
      refrence6: "",
    };
    const onSubmit = () => {};
    const validationSchema = Yup.object({
      refrence1: Yup.string().email("Invalid email format!").required("Email is required!"),
      refrence2: Yup.string().email("Invalid email format!").required("Email is required!"),
      refrence3: Yup.string().email("Invalid email format!").required("Email is required!"),
      refrence4: Yup.string().email("Invalid email format!").required("Email is required!"),
      refrence5: Yup.string().email("Invalid email format!").required("Email is required!"),
      refrence6: Yup.string().email("Invalid email format!").required("Email is required!"),

    });
  
    const onEdit = () => {
      setEdit(!isEdit);
    };

  return (
    <div className="">
      <div className="mt-4 refrence-info">
        <div className="p-3 d-flex justify-content-between">
          <h2 className="color">References</h2>
          <div className="">
            {isEdit === true && (
              <img
                className="me-3 edit_icon"
                src="/assets/save.svg"
                alt="save"
              />
            )}
            {isEdit === true ? (
              <button onClick={onEdit} className="btn">
                <img
                  className="edit_icon"
                  src="/assets/close.svg"
                  alt="close"
                />
              </button>
            ) : (
              <button onClick={onEdit} className="btn">
                <img className="edit_icon" src="/assets/edit.svg" alt="edit" />
              </button>
            )}
          </div>
        </div>

        <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="mb-5 p-3">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 1</label>
                    {isEdit === false ? (
                      <p>refrence1@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence1"
                          id="refrence1"
                          placeholder="Refrence Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="refrence1" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 2</label>
                    {isEdit === false ? (
                      <p>refrence2@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence1"
                          id="refrence1"
                          placeholder="Reference Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="lastname" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 3</label>
                    {isEdit === false ? (
                      <p>asadhamid27@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence3"
                          id="refrence3"
                          placeholder="Refrence Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="refrence1" />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 4</label>
                    {isEdit === false ? (
                      <p>refrence4@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence4"
                          id="refrence4"
                          placeholder="Refrence Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="refrence4" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 5</label>
                    {isEdit === false ? (
                      <p>refrence5@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence5"
                          id="refrence5"
                          placeholder="Refrence Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="refrence5" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4">
                  <label className="form_label">Refrence 6</label>
                    {isEdit === false ? (
                      <p>refrence6@gmail.com</p>
                    ) : (
                      <>
                        <Field
                          className="form-input placeholder"
                          type="email"
                          name="refrence6"
                          id="refrence6"
                          placeholder="Refrence Email"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="refrence6" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
      </div>
    </div>
  );
};

export default AddReference;
