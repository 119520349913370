import React from 'react';
import './contact-cards.css'; // For custom styles

const ContactCard = () => {
  return (
    <div className="container ">
      <div className="row">
        {/* Email Card */}
        <div className="col-md-6 mb-4">
          <div className="card contact-cards d-flex flex-row align-items-center p-5">
            <div className="mr-1 me-3">
              <img
                src="/assets/email.png"
                alt="Email Icon"
                className="contact-icons"
              />
            </div>
            <div>
              <h5 className="text-muted">Email Address</h5>
              <p className="font-weight-bold">arjun@apscholars.com</p>
            </div>
          </div>
        </div>

        {/* Phone Card */}
        <div className="col-md-6 mb-4">
          <div className="card contact-cards d-flex flex-row align-items-center p-5">
            <div className="mr-1 me-3">
              <img
                src="/assets/headphone.png"
                alt="Phone Icon"
                className="contact-icons"
              />
            </div>
            <div>
              <h5 className="text-muted">Phone Number</h5>
              <p className="font-weight-bold">(346) 241 6949</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
