import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./forgot-passwrod.style.css";
import { Link } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};
const onSubmit = () => {};
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format!").required("Required!"),
});
const ForgotPassword = () => {
  return (
    <>
 
        <div className="forgot_bg">
        <Link to="/" className="link-button top-left">
          <img
            className="arrow_icon"
            src="/assets/back_line_arrow.svg"
            alt="back"
          />
          <span className="button-text">Go to Main Website</span>
        </Link>
          <div className="container forgot-container">
          <div className="row form_box">
          <div className="col-lg-6">
            <div className="left-side">
                <img className="login_img" src="/assets/login.png" alt="backcover"/>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="p-sm-3">
              <div className="row">
                <div className="form_content content col-lg-12 col-md-12">
                  <h3>Password Recovery</h3>
                  <p>We’ll send you instruction on email</p>
                </div>
              </div>
              {/* form-start */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form className="">
                      <Field
                        className="form-input input_placeholder"
                        type="text"
                        name="email"
                        id="name"
                        placeholder="Email Address"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="email" />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="row">
                <div className="content col-sm">
                  <p className="login-paragraph float-left">
                    Return to
                    <Link to="/login" className="link-class">
                      <span className="forgot_span ms-1">Sign In</span>
                    </Link>
                  </p>
                </div>

                <div className="col-lg-12 col-md-12 mt-3">
                  <button className="form_btn">Reset Password</button>
                </div>
              </div>
              {/* form-end */}
            </div>
            </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default ForgotPassword;
