import React from "react";
import { PacmanLoader  } from "react-spinners";
import "./loader.css";

const Loader = ({ loading }) => {
  if (!loading) return null;

  return (
    <>
      <div className="modalBackdrop"></div>
      <div className="modalContent">
        <PacmanLoader 
          className="pulseLoaderOverride"
          size={20}
          color={"#48126A"}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Loader;
