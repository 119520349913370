import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Payment from "../../../stripe/Payment";
import "./payment-modal.css";
import { useSelector } from "react-redux";
import LoginForm from "./login";

const PaymnentModal = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "70%",
      height: "80%",
      transform: "translate(-50%, -50%)",
      marginTop: "30px",
    },
  };

  const { isOpen, onAfterOpen, onRequestClose } = props;
  const { user, isLogin } = useSelector((state) => state.users);

  // Local state to track login status within the modal
  const [isLoggedIn, setIsLoggedIn] = useState(isLogin);

  // Update local state when global login state changes
  useEffect(() => {
    setIsLoggedIn(isLogin);
  }, [isLogin]);

  // Determine the active step based on login status
  const currentStep = isLoggedIn ? 2 : 1;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Payment Modal"
      >
        <div className="d-flex justify-content-between">
          <h2>APScholars</h2>
          <button className="close-btn" onClick={onRequestClose}>
            <img className="close_icon" src="/assets/close.svg" alt="close" />
          </button>
        </div>
        <hr />
        <div className="payment-modal course-overview">
          <div className="steps-row">
            <div className={`step ${currentStep === 1 ? "active" : ""}`}>
              <div className="circle">1</div>
              <p>Login</p>
            </div>
            <div className="dotted-line"></div>
            <div className={`step ${currentStep === 2 ? "active" : ""}`}>
              <div className="circle">2</div>
              <p>Enrollment</p>
            </div>
            <div className="dotted-line"></div>
            <div className={`step ${currentStep === 3 ? "active" : ""}`}>
              <div className="circle">3</div>
              <p>All Done</p>
            </div>
          </div>

          {/* <h3>Course Overview</h3> */}
          {/* <h5 className="subject-title">
            Python - Complete Course Series For Beginners
          </h5> */}
          {/* <ul>
            <li>Week 1: Python Foundations - Variables, Strings, Conditions, Loops</li>
            <li>Week 2: Python Data Structures - Lists, Functions, Algorithms</li>
            <li>Week 3: Computer Science/Project - Create Task Project, Data, Internet</li>
          </ul> */}

          <h3 className="mt-3">Payment Information</h3>

          {/* Render LoginForm if not logged in, otherwise show Payment component */}
          {currentStep === 1 ? <LoginForm onLoginSuccess={() => setIsLoggedIn(true)} /> : <Payment />}
        </div>
      </Modal>
    </div>
  );
};

export default PaymnentModal;
