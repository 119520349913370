import React, {useEffect} from 'react';
import "../../App.css";
import CourseSidebar from "../../courses/course-sidebar/course-sidebar";
//import Navbar from '../navbar/navbar';
import { useNavigate } from 'react-router-dom';

const CoursesLayout = ({Component}) =>{
    const userLoggedIn = true; 
    const navigate = useNavigate();
    useEffect(()=>{
        if(!userLoggedIn)
        navigate("/login");
    },[])
    return (<>
        {/* <Navbar /> */}
        <div className="main">
            <div className="sidebar">{<CourseSidebar />}</div>
            <div className="main-wrapper2">
              <Component/>
            </div>
          </div>
    </>);
};

export default CoursesLayout;