import React, {useState, useEffect} from "react";
import "./sat-quiz-score.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuizScoreQuery } from "../../../../redux/apis/rtk";
import Loader from "../../../../shared/loader";


const SATScoreCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { testId, courseId } = useParams();
  const [totalScore, setTotalScore] = useState(0);
  const { data: resultData, isLoading } = useQuizScoreQuery(testId);

  console.log("check result", resultData)

  const navigateToScoreDetails = () => {
    navigate(`/course-details/${courseId}/${testId}/scoredetail`, { state: { resultData } });
  }; 

  const navigateToQuiz = () => {
    navigate("/course-details/satquiz");
  };

  useEffect(() => {
    if (resultData) {
      const totalScore = resultData.reduce((acc, module) => acc + module.score, 0);
      setTotalScore(totalScore);
    }
  }, [resultData]);


  return (
    <>
      <div className="padding">
        <div className="score-card mt-5">
          <h2 className="course-title">SAT Practice 1</h2>
          <div className="total-score">Your Total Score</div>
          <h1>{totalScore}</h1>
          {resultData && resultData.map((module, index) => (
            <div key={index}>
              <div>
                Module {index + 1} Score: {module.score}
              </div>
            </div>
          ))}
          <div className="buttons-container">
            <button
              className="view-details-button"
              onClick={navigateToScoreDetails}
            >
              Score Details
            </button>
            {/* <button className="retake-button" onClick={navigateToQuiz}>
              Retake Test
            </button> */}
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
    </>
  );
};

export default SATScoreCard;
