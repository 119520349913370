import React from "react";
import Copyrights from "../copyrights/copyrights";
import Footer from "../page-footer/page-footer";
import PageNavbar from "../page-navbar/page-navbar";
import BookAppointment from "./book-appointment/book-appointment";
import HeroBanner from "./hero-section/hero-section";
import OurCourses from "./our-courses/our-courses";
import PageVideo2 from "./page-video-2/page-video";
import PageVideo from "./page-video/page-video";
import ProvenResult from "./proven-result/proven-result";
import SuccessStories from "./success-stories/success-stories";
import ReviewHighlights from "./testimonial-stories/testimonialStories";
import WHYAPScholars from "./whyAPScholars/whyAPScholars";

const LandingPage = (props) => {
  return (
    <>
      <PageNavbar />
      <HeroBanner />
      <PageVideo2 />
      <WHYAPScholars />
      <OurCourses />
      {/* <ReviewHighlights /> */}
      {/* <SuccessStories /> */}
      <ProvenResult />
      <PageVideo />
      <BookAppointment />
      <Footer />
      {/* <Copyrights /> */}
    </>
  );
};

export default LandingPage;
