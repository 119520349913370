import React, { useEffect, useState } from "react";
import "./chapter-quiz.css";
import { Link } from "react-router-dom";
import HttpService from "../../../services/services";
import { useChapterQuizQuery } from "../../../redux/apis/rtk";
import Loader from "../../../shared/loader";
//import { useParams } from "react-router-dom";

const ChapterQuiz = () => {
 // const { id } = useParams();
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  //const [fibanswer, setFibAnswer] = useState({});
 // const [fib, setFib] = useState(false);
  const [play, setPlay] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });
  // const [isLoading, setIsLoading] = useState(true);
  const courseID = "6601204a47d35f818eaa2e38";
  const type = "MCQ";
  //const params = { courseID, type };


  const [quiz, setquizdata] = useState([]);

  const { data: coursesData, isLoading } = useChapterQuizQuery();

  useEffect(() => {
    if (coursesData) {
      setquizdata(coursesData); // Assuming coursesData is an array of quiz questions
    }
  }, [coursesData]);

  
  console.log("check quiz", coursesData)

  const onClickNext = () => {
    setIsSubmitted(false);
    setPlay(false);
    setSelectedAnswerIndex(null);
    //setFibAnswer(null);
    if (isSubmitted) {
      setResult((prevResult) => ({
        score: prevResult.score,
        correctAnswers: prevResult.correctAnswers,
        wrongAnswers: prevResult.wrongAnswers + 1,
      }));
    }
    if (activeQuestion !== quiz.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
    }
  };

  const SubmitAnswer = () => {
    setIsSubmitted(true);

    setTimeout(() => {
      setPlay(true);
    }, 5000);
    // setIsOpen(true);
    // if (fibanswer.answer === fibanswer.question.correctAnswer) {
    //   setFib(true);
    // } else {
    //   setFib(false);
    // }
  };

  const onClickProvious = () => {
    setPlay(false);
  };

  const handleAnswerOptionClick = (isCorrect, index) => {
    setSelectedAnswerIndex(index);
    setSubmitDisabled(false);
    if (isCorrect) {
      setResult((prevResult) => ({
        score: prevResult.score + 5,
        correctAnswers: prevResult.correctAnswers + 1,
        wrongAnswers: prevResult.wrongAnswers,
      }));
    } else {
      setResult((prevResult) => ({
        score: prevResult.score,
        correctAnswers: prevResult.correctAnswers,
        wrongAnswers: prevResult.wrongAnswers + 1,
      }));
    }
    if (selectedAnswerIndex === index) {
      setSubmitDisabled(true);
    }
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  return (
    <>
     {isLoading ? (
        <div className="loading-message">
          <div className="spinner"></div>
          {/* Loading... */}
        </div>
      ) : (
      <div className="padding">
        <div className="mt-4 top-navigation d-flex justify-content-start">
          <Link to="/course-details/video">
            <img
              className="me-3 arrow_icon"
              src="/assets/left_arrow.svg"
              alt="previous"
            />
          </Link>
          <Link to="/course-details/task">
            <img
              className="arrow_icon"
              src="/assets/right_arrow.svg"
              alt="next"
            />
          </Link>
        </div>

        <div>
          {!showResult ? (
            <>
              <div className="mt-5">
                <span className="active-question-no">
                  {addLeadingZero(activeQuestion + 1)}
                </span>
                <span className="total-question">
                  /{addLeadingZero(quiz.length)}
                </span>
              </div>

              <>
                <div className="quiz-container">
                  {play ? (
                    <div className="">
                      <iframe
                        title="video"
                        className="quiz_video_frame"
                        src="https://www.youtube.com/embed/uXWycyeTeCs"
                      ></iframe>
                    </div>
                  ) : (
                    <div>
                      {/* Question Display Code Start*/}
                      {quiz.length > 0 && activeQuestion < quiz.length && (
                        <div className="question-section">
                          <div className="question-text mt-4 ms-5">
                            <h2 className="color">
                              {"Q : " + quiz[activeQuestion].question.text}
                            </h2>
                          </div>
                          {quiz[activeQuestion].question.code && (
                            <pre>
                              <code>{quiz[activeQuestion].question.code}</code>
                            </pre>
                          )}
                          {quiz[activeQuestion].question.footer && (
                            <p className="question-footer ms-5">
                              {quiz[activeQuestion].question.footer}
                            </p>
                          )}
                          {quiz[activeQuestion].question.codefooter && (
                            <pre>
                              <code>
                                {quiz[activeQuestion].question.codefooter}
                              </code>
                            </pre>
                          )}
                        </div>
                      )}
                      {/* Question Display Code End*/}

                      {/* MCQS Answer List Start*/}
                      {quiz.length > 0 && activeQuestion < quiz.length && (
                        <div className="mcq-answer-section ms-2 me-5 gap-3">
                          <ul>
                            {quiz[activeQuestion]?.type === "MCQ" &&
                              quiz[activeQuestion]?.choices.map(
                                (choices, index) => (
                                  <>
                                    <li
                                      onClick={() =>
                                        !isSubmitted &&
                                        handleAnswerOptionClick(
                                          choices.isCorrect,
                                          index
                                        )
                                      }
                                      key={choices}
                                      className={`${
                                        selectedAnswerIndex === index
                                          ? "selected-answer"
                                          : ""
                                      }
              ${
                isSubmitted
                  ? choices.isCorrect
                    ? "correct"
                    : selectedAnswerIndex === index && !choices.isCorrect
                    ? "incorrect"
                    : ""
                  : ""
              }`}
                                    >
                                      {choices.code ? (
                                        <div className="answerlist">
                                          {choices.code}
                                        </div>
                                      ) : (
                                        <div className="answerlist">
                                          {choices.content}
                                        </div>
                                      )}
                                    </li>
                                    {isSubmitted &&
                                      selectedAnswerIndex === index && (
                                        <pre className="answer-explanation ms-3 mt-1">
                                          {choices.explanation}
                                        </pre>
                                      )}
                                  </>
                                )
                              )}
                          </ul>
                        </div>
                      )}
                  
                 
                      <div className="flex-center mb-3 ps-5">
                        <button
                          className="quiz-button"
                          data-bs-toggle="modal"
                          data-bs-target="#VideoModal"
                          disabled={submitDisabled}
                          // disabled={selectedAnswerIndex === null}
                          onClick={SubmitAnswer}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex-right mt-3">
                  <button
                    className="quiz-button"
                    onClick={onClickProvious}
                    disabled={!isSubmitted}
                  >
                    Previous
                  </button>
                  <button
                    className="quiz-button"
                    onClick={onClickNext}
                    disabled={!isSubmitted}
                  >
                    {activeQuestion === quiz.length - 1 ? "Finish" : "Next"}
                  </button>
                </div>
              </>
            </>
          ) : (
            <div className="quiz-container result mt-5 p-5">
              <h3>Result</h3>
              <p>
                Total Question: <span>{quiz.length}</span>
              </p>
              <p>
                Total Score: <span>{result.score}</span>
              </p>
              <p>
                Correct Answers: <span>{result.correctAnswers}</span>
              </p>
              <p>
                Wrong Answers: <span>{result.wrongAnswers}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      )}
      <Loader loading={isLoading} />
      {/* {isSubmitted && (
        <QuizVideoModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
        />
      )} */}
      
    </>
  );
};

export default ChapterQuiz;
