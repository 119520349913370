import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useEnrollmentCourseMutation } from "../redux/apis/rtk";
import { useSelector } from "react-redux";

function Payment({ paymentIntentId }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [enrollmentCourse, { isLoading, isSuccess, isError, error }] = useEnrollmentCourseMutation();
  
  const courses = useSelector((state) => state.courses);
  const courseId = courses.courseId; // Get courseId from the Redux store

  console.log("Courses state:", courseId);

  useEffect(() => {
    fetch("https://apscholars-api.azurewebsites.net/api/Course/stripe-config").then(async (r) => {
      const { publishedKey } = await r.json();
      setStripePromise(loadStripe(publishedKey));
    });
  }, []);

  useEffect(() => {
    const amount = 100; 
    const formData = new URLSearchParams();
    formData.append("Amount", amount);

    fetch("https://apscholars-api.azurewebsites.net/api/Course/payment-intent", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(async (result) => {
      const { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  // Handle payment success
  // const handlePaymentSuccess = async (paymentResult) => {
  //   console.log("check this API working")
  //   const jsonData = {
  //     paymentResult, 
  //     otherData: "Additional data for enrollment", 
  //   };
    
  //   // Pass courseId here
  //   await enrollmentCourse({ courseId, paymentIntentId, jsonData });
  // };

  const handlePaymentSuccess = async (paymentIntentId) => {
    // const jsonData = {
    //     paymentResult: paymentIntentId, 
    //     otherData: courseId, 
    // };
    // const apiUrl = `https://apscholars-api.azurewebsites.net/api/Course/${courseId}/paymentId/${paymentIntentId}`;
    // console.log("Full API URL for enrollmentCourse:", apiUrl);
    console.log("Calling enrollmentCourse with:", {
        courseId, 
        paymentIntentId
    });

    try {
        const response = await enrollmentCourse({ courseId, paymentIntentId }).unwrap(); // Unwrap the response
        console.log("Enrollment API response:", response);
        console.log("Status: 200 - Request was successful");
    } catch (error) {
        console.error("Enrollment API call failed:", error);
        if (error.status === 400) {
            console.error("Bad Request:", error.data);
        }
    }
};

  

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm onPaymentSuccess={handlePaymentSuccess} />
        </Elements>
      )}

      {/* Handle loading, error, success states */}
      {isLoading && <p>Processing enrollment...</p>}
      {isSuccess && <p>Enrollment successful!</p>}
      {isError && <p>Error: {error.message}</p>}
    </>
  );
}

export default Payment;




// import { useEffect, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
// import { loadStripe } from "@stripe/stripe-js";
// import { useDispatch } from "react-redux";
// import { useEnrollmentCourseMutation } from "../redux/apis/rtk";

// function Payment() {
//   const [stripePromise, setStripePromise] = useState(null);
//   const [clientSecret, setClientSecret] = useState("");

//   const [enrollmentCourse, { isLoading, data, isSuccess, isError, error }] = useEnrollmentCourseMutation();

//   useEffect(() => {
//     fetch(
//       "https://apscholars-api.azurewebsites.net/api/Course/stripe-config"
//     ).then(async (r) => {
//       const { publishedKey } = await r.json();
//       console.log("check key", publishedKey);
//       setStripePromise(loadStripe(publishedKey));
//     });
//   }, []);

//   useEffect(() => {
//     const amount = 100;
//     const formData = new URLSearchParams();
//     formData.append("Amount", amount);

//     fetch(
//       "https://apscholars-api.azurewebsites.net/api/Course/payment-intent",
//       {
//         method: "POST",
//         body: formData,
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       }
//     ).then(async (result) => {
//       const { clientSecret } = await result.json();
//       setClientSecret(clientSecret);
//     });
//   }, []);

//   console.log("check promise", stripePromise);
//   console.log("check secret", clientSecret);
//   return (
//     <>
//       {/* <h6>React Stripe and the Payment Element</h6> */}
//       {clientSecret && stripePromise && (
//         <Elements stripe={stripePromise} options={{ clientSecret }}>
//           <CheckoutForm />
//         </Elements>
//       )}
//     </>
//   );
// }

// export default Payment;

// import React, { useEffect, useState } from 'react';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import CheckoutForm from './CheckoutForm';

// const Payment = () => {
//     const [clientSecret, setClientSecret] = useState('');
//     const [stripePromise, setStripePromise] = useState(null);

//   useEffect(() => {
//     fetch(
//       "https://apscholars-api.azurewebsites.net/api/Course/stripe-config"
//     ).then(async (r) => {
//       const { publishedKey } = await r.json();
//       console.log("check key", publishedKey);
//       setStripePromise(loadStripe(publishedKey));
//     });
//   }, []);

//     useEffect(() => {
//         // Fetch client secret from the backend
//         const fetchClientSecret = async () => {
//             const amount = 1050; // Replace with the actual amount
//             const formData = new URLSearchParams();
//             formData.append("Amount", amount.toString());

//             const response = await fetch('https://apscholars-api.azurewebsites.net/api/Course/payment-intent', {
//                 method: "POST",
//                 body: formData,
//                 headers: {
//                     "Content-Type": "application/x-www-form-urlencoded",
//                 },
//             });
//             const { clientSecret } = await response.json();
//             setClientSecret(clientSecret);
//         };

//         fetchClientSecret();
//     }, []);

//     return (
//         <div>
//             {clientSecret && (
//                 <Elements stripe={stripePromise} options={{ clientSecret }}>
//                     <CheckoutForm />
//                 </Elements>
//             )}
//         </div>
//     );
// };

// export default Payment;
