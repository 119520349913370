import React from "react";
import "./prepareing-test.css";
import { useSATQuizQuery } from "../../../../redux/apis/rtk";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PrepareTest = () => {
  const { testId, courseId } = useParams();
    const navigate = useNavigate();
    const {data: modulesData, error, isLoading } = useSATQuizQuery(testId);

    console.log("hello data", modulesData)

    const quizStart = () => {
        if (modulesData) {
            const moduleIds = modulesData.map(module => module.id);
            const area = modulesData.map(module => module.area);
            const name = modulesData.map(module => module.name);
            navigate(`/course-details/${courseId}/${testId}`, {
                state: { moduleIds, area, name }
            });
        }
    };

  return (
    <div className="padding mt-5">

   <h2 className="text-center">Practice Test</h2>
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <div className="white-card p-3 d-flex flex-column align-items-start">
        <div className="d-flex align-items-start mb-3">
          <img alt="Icon1" className="card-icon me-3 mt-1" src="/assets/clock.svg" />
          <div>
            <h3 className="mb-0">Timing</h3>
            <p className="mb-0 paragraph">
              Practice tests are timed, you cannot paused them. To countinue on another device, you have to start over. 
            </p>
          </div>
        </div>
        <div className="d-flex align-items-start mb-3">
          <img alt="Icon2" className="card-icon me-3 mt-1" src="/assets/scoresheet.png" />
          <div>
            <h3 className="mb-0">Scores</h3>
            <p className="mb-0 paragraph">
              When you finish the tests, go to My Courses and click on the course card to view the score and review the test. 
            </p>
          </div>
        </div>
        <div className="d-flex align-items-start mb-3">
          <img alt="Icon3" className="card-icon me-3 mt-1" src="/assets/clock.svg" />
          <div>
            <h3 className="mb-0">AT</h3>
            <p className="mb-0 paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-start">
          <img alt="Icon4" className="card-icon me-3 mt-1" src="/assets/lock.svg" />
          <div>
            <h3 className="mb-0">No Device Lock</h3>
            <p className="mb-0 paragraph">
              We don't lock you device during test.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="flex-right">
              {/* <button
                className="quiz-button"
                onClick={handleBackButtonClick}
                disabled={currentQuestion === 0}
              >
                Back
              </button> */}
              <button
                className="quiz-button"
                onClick={quizStart}
                // onClick={() =>
                //   quizResult !== null
                //     ? handleSubmitButtonClick()
                //     : handleNextButtonClick()
                // }
              >
               Start Quiz
              </button>
            </div>

    </div>
  );
};

export default PrepareTest;
