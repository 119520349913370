import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaBars } from "react-icons/fa";
import "./course-sidebar.css";
import { Link } from "react-router-dom";
import SidebarData from "../../shared/sidenavData";
import CourseNavbar from "../course-navbar/course-navbar";
import { useParams } from "react-router-dom";
import { useQuizSidebarQuery } from "../../redux/apis/rtk";
import QuizSidebar from "../../shared/course-menu.json";
import { useLocation } from "react-router-dom";

const CourseSidebar = () => {
  const [collapse, setCollapse] = useState(true);
  const showSidebar = () => setCollapse(true);
  const location = useLocation();
  const courseId = location.pathname.split("/")[2];

  const {
    data: quizSidebarData,
    error,
    isLoading,
  } = useQuizSidebarQuery(courseId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log("hello check", quizSidebarData);
  return (
    <>
      <ProSidebar className="sidebar" collapsed={collapse}>
        <div className="mt-3 logo-image d-flex justify-content-center">
          <img src="/assets/logo.png" alt="logo.png" />
        </div>
        <div className={collapse ? "hamburger2" : "hamburger"}>
          <FaBars onClick={() => setCollapse(!collapse)} />
        </div>
        <SidebarHeader>
          {collapse ? (
            <div className="courses-sidebar-brand mt-3 d-flex justify-content-center">
              <img src="/assets/java.png" alt="Java" />
            </div>
          ) : (
            <div className="courses-sidebar-brand mt-3 d-flex justify-content-center">
              <img className="me-2" src="/assets/java.png" alt="Java" />
              {/* <h2 className="title">Java</h2> */}
            </div>
          )}
        </SidebarHeader>
        <SidebarContent>
          {/* <Menu iconShape="square">
            {quizSidebarData &&
              quizSidebarData.map((item, index) => (
                <SubMenu title={item.menuName} key={`submenu-${index}`}>
                  {item.content.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} onClick={showSidebar}>
                      {subItem.isQuizCompleted ? (
                        <Link
                          to={`/course-details/${courseId}/${subItem.contentId}/scorecard`}
                          toggleSidebar={showSidebar}
                        >
                          {subItem.title}
                        </Link>
                      ) : (
                        <Link
                          to={`/course-details/${courseId}/${subItem.contentId}/prepare`}
                          toggleSidebar={showSidebar}
                        >
                          {subItem.title}
                        </Link>
                      )}
                    </MenuItem>
                  ))}
                   <MenuItem>
                    <Link
                          to={`/course-details/${courseId}/6601204a47d35f818eaa2e38/quiz`}
                          toggleSidebar={showSidebar}
                        >
                            Hello
                        </Link>
                   </MenuItem>
                </SubMenu>
              ))}
          </Menu> */}

          <Menu iconShape="square">
            {quizSidebarData &&
              quizSidebarData.map((item, index) => (
                <SubMenu
                  key={`submenu-${index}`}
                  title={
                    <div className="d-flex align-items-center">
                      <img
                        src={item.menuIconUrl} // Replace with the icon path or data
                        alt={`${item.menuName}-icon`}
                        className="menu-icon me-2" // Add margin for spacing
                        style={{ width: "16px", height: "16px" }}
                      />
                      {!collapse && <span>{item.menuName}</span>}{" "}
                      {/* Only show text when expanded */}
                    </div>
                  }
                >
                  {item.content.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} onClick={showSidebar}>
                      {subItem.isQuizCompleted ? (
                        <Link
                          to={`/course-details/${courseId}/${subItem.contentId}/scorecard`}
                          toggleSidebar={showSidebar}
                          className="d-flex align-items-center"
                        >
                          <img
                            src={subItem.contentImageIconUrl} // Replace with the icon path or data
                            alt={`${subItem.contentImageIconUrl}-icon`}
                            className="menu-icon me-2"
                            style={{ width: "16px", height: "16px" }}
                          />
                          <span>{subItem.title}</span>
                          {/* {!collapse && <span>{subItem.title}</span>}{" "} */}
                          {/* Only show text when expanded */}
                        </Link>
                      ) : (
                        <Link
                          to={`/course-details/${courseId}/${subItem.contentId}/prepare`}
                          toggleSidebar={showSidebar}
                          className="d-flex align-items-center"
                        >
                          <img
                            src={subItem.contentImageIconUrl} // Replace with the icon path or data
                            alt={`${subItem.contentImageIconUrl}-icon`}
                            className="menu-icon me-2"
                            style={{ width: "16px", height: "16px" }}
                          />
                          <span>{subItem.title}</span>
                          {/* {!collapse && <span>{subItem.title}</span>}{" "} */}
                          {/* Only show text when expanded */}
                        </Link>
                      )}
                    </MenuItem>
                  ))}
                </SubMenu>
              ))}
          </Menu>

          {/* <Menu iconShape="square">
            <SubMenu title={quizSidebarData.menuName}>
              {quizSidebarData.content.map((item, index) => (
                <MenuItem key={index} onClick={showSidebar}>
                  <Link
                    to={`/course-details/${courseId}/${item.contentId}/prepare`}
                    toggleSidebar={showSidebar}
                  >
                    {item.title}
                  </Link>
                </MenuItem>
              ))}
            </SubMenu>
          </Menu> */}
        </SidebarContent>
      </ProSidebar>

      <CourseNavbar collapse={collapse} />
    </>
  );
};

export default CourseSidebar;
