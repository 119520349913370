import React from "react";
import Copyrights from "../copyrights/copyrights";
import Footer from "../page-footer/page-footer";
import PageNavbar from "../page-navbar/page-navbar";
import ContactBanner from "./contact-banner/contact-banner";
import ContactCard from "./contact-cards/contact-cards";
import ContactForm from "./contact-form/contact-form";

const ContactUs = () => {
  return (
    <>
      <PageNavbar />
      {/* <ContactBanner /> */}
      <ContactCard />
      <ContactForm />
      <Footer />
      {/* <Copyrights /> */}
    </>
  );
};

export default ContactUs;
