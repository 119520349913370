import React from "react";
import AddReference from "./add-refrence/add-refrence";
import MyProfile from "./my-profile/my-profile";

const StudentProfile = () => {
    return (
        <>
            <MyProfile />
            <AddReference />
        </>
    )
}

export default StudentProfile;