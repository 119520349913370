import axios from "axios";

class HttpService {
  constructor() {
    this.BASE_URL = "https://apscholars-api.azurewebsites.net/";
    this.accessTokenString = localStorage.getItem("access_token");
    
  }

  async request(route, method, data = null,  params = {}) {
    // const config = {
    //   params: params,
    // };

    try {
      const response = await axios[method](this.BASE_URL + route, data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  getWithoutToken(route) {
    return this.request(route, "get");
  }

    get(route) {
      return this.request(route, "get", {
        headers: { Authorization: "Bearer " + this.accessTokenString }
    });
  }

  post(route, data) {
    return this.request(route, "post", data, {
      headers : { 'Content-Type' : 'application/x-www-form-urlencoded'}
    });
  }

  postwithToken(route, data) {
    return this.request(route, "post", {
      headers: { Authorization: "Bearer " + this.accessTokenString, 
      "Content-Type": "application/json", }
  });
}

  put(route, data) {
    return this.request(route, "put", data);
  }

  delete(route) {
    return this.request(route, "delete");
  }

  getByID(route, ID) {
    return this.request(route + "/" + ID, "get");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user-info"));
  }

  getCurrentUserToken() {
    return this.TOKEN;
  }

  getCurrentUserId() {
    return this.getCurrentUser().Id;
  }
}

export default new HttpService();
