import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import MyCourses from "./components/my-courses/my-courses";
import StudentProfile from "./components/student-profile/student-profile";
import ChapterOverview from "./courses/course-details/chapter-overview/chapter-overview";
import ChapterVideo from "./courses/course-details/chapter-video/chapter-video";
import ChapterQuiz from "./courses/course-details/chapter-quiz/chapter-quiz";
import ChapterTasks from "./courses/course-details/chapter-tasks/chapter-tasks";
import Login from "./components/auth/login/login.component";
import ForgotPassword from "./components/auth/forgot-password/forgot-passwrod.component";
import SignUp from "./components/auth/signup/signup.component";
import LandingPage from "./pages/landing-page/landing-page";
import CourseEnrollment from "./pages/course-enrollment/course-enrollment";
import ContactUs from "./pages/contact-page/contact-us";
import AboutUs from "./pages/about-page/about-us";
import FibQuiz from "./courses/course-details/chapter-quiz/fib-quiz";
import ScrollToTop from "./shared/scrolltoTop";
import DashboardLayout from "./components/HOC/dashboardLayout";
import CoursesLayout from "./components/HOC/coursesLayout";
import OTPVerfication from "./components/auth/otp-verification/otp-verification.component";
import SATQuiz from "./courses/course-details/SAT-module/sat-module";
import SATScoreCard from "./courses/course-details/SAT-module/sat-quiz-score/sat-quiz-score";
import QuizDetails from "./courses/course-details/SAT-module/sat-quiz-details/sat-quiz-details";
import AnswerDetail from "./courses/course-details/SAT-module/answer-details/answer-details";
import { useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store/store";
import Toaster from "./shared/toaster";
import MathModule from "./courses/course-details/SAT-module/math-module/math-module";
import PrepareTest from "./courses/course-details/SAT-module/prepareing-test/prepareing-test";
import Payment from "./stripe/Payment";
import Completion from "./stripe/Completion";
import DesmosCalculator from "./components/desmos-calculator/desmos";
import ReferencesSheet from "./components/desmos-calculator/references";

const App = () => {
  const { user } = useSelector((state) => state.users);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <PersistGate loading={null} persistor={persistor}>
          <Routes path="/">
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otp" element={<OTPVerfication />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/enrollment/:enrollCourseId" element={<CourseEnrollment />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/completion" element={<Completion />} />
            <Route path="/desmos" element={<DesmosCalculator />} />
            <Route path="/sheet" element={<ReferencesSheet />} />
            
            {/* <Route path="/" element={<Payment />} />
            <Route path="/completion" element={<Completion />} /> */}
          
          
            <Route path="/">
              <Route
                path="/dashboard"
                element={<DashboardLayout Component={Dashboard} />}
              />
              <Route
                path="/my-courses"
                element={<DashboardLayout Component={MyCourses} />}
              />
              <Route
                path="/my-profile"
                element={<DashboardLayout Component={StudentProfile} />}
              />
            </Route>
            
            <Route path="/course-details/:courseId">
            <Route
                path="/course-details/:courseId/:testId/prepare"
                element={<CoursesLayout Component={PrepareTest} />}
              />
            
              <Route
                path="/course-details/:courseId/chapter"
                element={<CoursesLayout Component={ChapterOverview} />}
              />
              <Route
                path="/course-details/:courseId/:testId"
                element={<CoursesLayout Component={MathModule} />}
              />
              {/* <Route
                path="/course-details/satquiz"
                element={<CoursesLayout Component={SATQuiz} />}
              /> */}
              <Route
                path="/course-details/:courseId/:testId/scorecard"
                element={<CoursesLayout Component={SATScoreCard} />}
              />
              {/* <Route
                path="/course-details/mathmodule"
                element={<CoursesLayout Component={MathModule} />}
              /> */}
              
              <Route
                path="/course-details/:courseId/:testId/scoredetail"
                element={<CoursesLayout Component={QuizDetails} />}
              />
              <Route
                path="/course-details/:courseId/:testId/answerdetail"
                element={<CoursesLayout Component={AnswerDetail} />}
              />
               <Route
                path="/course-details/:courseId/:testId/quiz"
                element={<CoursesLayout Component={ChapterQuiz} />}
              />
              {/* <Route
                path="/course-details/video"
                element={<CoursesLayout Component={ChapterVideo} />}
              />
              <Route
                path="/course-details/fib-quiz/:id"
                element={<CoursesLayout Component={FibQuiz} />}
              />
              <Route
                path="/course-details/quiz/:id"
                element={<CoursesLayout Component={ChapterQuiz} />}
              />
              <Route
                path="/course-details/task"
                element={<CoursesLayout Component={ChapterTasks} />}
              /> */}
            </Route>
          </Routes>
        </PersistGate>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

export default App;
