import React from "react";
import "./hero-section.css";

const HeroBanner = () => {
  return (
    <div className="banner">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
            <div className="">
              <div className="title-main">STEM & COMPUTER SCIENCE CAREERS</div>
              <h1 className="sub-title">WE COACH KIDS FOR STEM CAREERS</h1>
            </div>
          </div>
          <div
            className="col-lg-6 order-1 order-lg-2 img"
            style={{ backgroundImage: `url(${"/assets/1.png"})` }} 
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
        {/* <div className="banner-intro-box">
                    <div className="intro-img">
                        <img className="spine2" src="/assets/intro-box.png" alt="intro-box" />
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default HeroBanner;
