import React, { useEffect } from "react";
import Copyrights from "../copyrights/copyrights";
import BookAppointment from "../landing-page/book-appointment/book-appointment";
import PageVideo from "../landing-page/page-video/page-video";
import Footer from "../page-footer/page-footer";
import PageNavbar from "../page-navbar/page-navbar";
import EnrollmentBanner from "./course-enrollment-banner/course-enrollment-banner";
import CourseOverview from "./course-overview/course-overview";
import Enrollment from "./enrollment/enrollment";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCourseId } from "../../redux/reducers/reducer";

const CourseEnrollment = () => {

  const { enrollCourseId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCourseId(enrollCourseId));
    console.log("check course id", enrollCourseId)
  }, [enrollCourseId, dispatch]);

 
  return (
    <>
      <PageNavbar />
      {/* <EnrollmentBanner /> */}
      <CourseOverview />
      {/* <div className="container py-5">
        <div className="row g-4">
          <div className="col-lg-9 col-md-12">
            
          </div>
          <div className="col-lg-3 col-md-12">
            <Enrollment />
          </div>
        </div>
      </div> */}
      <PageVideo />
      <BookAppointment />
      <Footer />
      {/* <Copyrights /> */}
    </>
  );
};

export default CourseEnrollment;
