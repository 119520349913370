import React, { useState, useEffect } from "react";
import "../english-module/english-module.css";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import {
  useSATModuleQuery,
  useSaveResultMutation,
} from "../../../../redux/apis/rtk";
import Loader from "../../../../shared/loader";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MathModule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLogin } = useSelector((state) => state.users);
  const SatmoduleIds = location.state ? location.state.moduleIds : [];
  const area = location.state ? location.state.area : [];
  const name = location.state ? location.state.name : [];
  const [currentarea, setCurrentArea] = useState(null);
  const [currentmodulename, setCurrentModuleName] = useState(null);
  const { testId, courseId } = useParams();
  const [currentQuestionData, setcurrentQuestionData] = useState([]);
  const [totalQuestions, settotalQuestions] = useState();
  const [currentModuleId, setCurrentModuleId] = useState(null);
  const moduleId = currentModuleId;
  const { data: coursesData, isLoading } = useSATModuleQuery(moduleId);
  const [saveResult, { isSuccess, isError, error }] = useSaveResultMutation();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [secondsRemaining, setSecondsRemaining] = useState(180);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [startTime, setStartTime] = useState(Date.now());
  const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});

  const navigateToPage = () => {
    setQuizResult(result);
  };

  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  useEffect(() => {
    if (coursesData) {
      settotalQuestions(coursesData.length);
      setcurrentQuestionData(coursesData[currentQuestion]);
    }
  }, [coursesData, currentQuestion]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [secondsRemaining, result]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setQuizResult(result);
    }
  }, [secondsRemaining, result]);

  useEffect(() => {
    if (!isLoading && SatmoduleIds.length > 0) {
      setCurrentModuleId(SatmoduleIds[0]); // Set the currentModuleId to the first module ID
    }
  }, [SatmoduleIds, isLoading]);

  const handleAnswerSelection = (selectedAnswer, choiceId, isCorrect) => {
    setSelectedAnswerIndex(selectedAnswer);
    setSubmitDisabled(false);
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: {
        selectedAnswer: choiceId,
        isCorrect,
        userInput: "",
      },
    }));

    if (isCorrect) {
      if (currentQuestionData[currentQuestion]) {
        setResult((prevResult) => ({
          score: prevResult.score + currentQuestionData[currentQuestion].score,
          correctAnswers: prevResult.correctAnswers + 1,
          wrongAnswers: prevResult.wrongAnswers,
        }));
      } else {
        console.log(
          "currentQuestionData[currentQuestion] is undefined or null"
        );
      }
    } else {
      setResult((prevResult) => ({
        score: prevResult.score,
        correctAnswers: prevResult.correctAnswers,
        wrongAnswers: prevResult.wrongAnswers + 1,
      }));
    }
  };

  // For FIB type questions
  // const handleInputChange = (event) => {
  //   const input = event.target.value;
  //   setUserInput(input);
  //   setUserAnswers((prevAnswers) => ({
  //     ...prevAnswers,
  //     [currentQuestion]: {
  //       selectedAnswer: null, // For FIB, selectedAnswer will be null
  //       isCorrect: null, // For FIB, isCorrect will be null
  //       userInput: input, // Store the user input for FIB questions
  //     },
  //   }));
  // };

  const handleInputChange = (event) => {
    const userInput = event.target.value;
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: {
        userInput,
      },
    }));
  };

  const handleNextButtonClick = () => {
    const currentTime = Date.now();
    const timeSpent = currentTime - startTime;

    setTimeSpentPerQuestion((prevTimes) => ({
      ...prevTimes,
      [currentQuestion]: timeSpent,
    }));

    if (currentQuestion < totalQuestions - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setStartTime(currentTime); // Reset start time for the next question
      setSelectedAnswerIndex(null);
      setSubmitDisabled(true);
    } else {
      setQuizResult(result);
    }
  };

  const handleBackButtonClick = () => {
    const currentTime = Date.now();
    const timeSpent = currentTime - startTime;

    setTimeSpentPerQuestion((prevTimes) => ({
      ...prevTimes,
      [currentQuestion]: timeSpent,
    }));

    if (currentQuestion > 0 && quizResult === null) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      setStartTime(currentTime); // Reset start time for the next question
      setSelectedAnswerIndex(null);
      setSubmitDisabled(true);
    }
  };

  // const handleSubmitButtonClick = async () => {
  //   const quizResultToPass = Object.entries(userAnswers).map(
  //     ([questionIndex, answer]) => {
  //       const questionData = coursesData[questionIndex];
  //       return {
  //         quizId: questionData.quizId,
  //         userChoiceId:
  //           questionData.type === "FIB" ? "" : answer?.selectedAnswer,
  //         answer: questionData.type === "FIB" ? answer.userInput : "",
  //         timeSpent: null,
  //       };
  //     }
  //   );

  //   try {
  //     // Save the quiz result
  //     await saveResult({
  //       moduleId: currentModuleId,
  //       answers: quizResultToPass,
  //     });

  //     // If saving is successful, increment the module ID
  //     const nextModuleIndex = SatmoduleIds.indexOf(currentModuleId) + 1;
  //     if (nextModuleIndex < SatmoduleIds.length) {
  //       setCurrentModuleId(SatmoduleIds[nextModuleIndex]);
  //       setCurrentQuestion(0);
  //       setUserAnswers({});
  //       setQuizResult(null);
  //     } else {
  //       navigate(`/course-details/${courseId}/${testId}/scorecard`);
  //     }
  //   } catch (error) {
  //     console.error("Error saving quiz result:", error);
  //     // Handle error if necessary
  //   }
  // };

  const handleSubmitButtonClick = async () => {
    const currentTime = Date.now();
    const timeSpent = currentTime - startTime;

    setTimeSpentPerQuestion((prevTimes) => ({
      ...prevTimes,
      [currentQuestion]: timeSpent,
    }));

    const quizResultToPass = Object.entries(userAnswers).map(
      ([questionIndex, answer]) => {
        const questionData = coursesData[questionIndex];
        return {
          quizId: questionData.quizId,
          userChoiceId:
            questionData.type === "FIB" ? "" : answer?.selectedAnswer,
          answer: questionData.type === "FIB" ? answer.userInput : "",
          // timeSpent: (timeSpentPerQuestion[questionIndex] || 0).toString(),
          timeSpent: ((timeSpentPerQuestion[questionIndex] || 0) / 1000)
            .toFixed(2)
            .toString(),
        };
      }
    );

    try {
      // Save the quiz result
      await saveResult({
        moduleId: currentModuleId,
        answers: quizResultToPass,
      });

      // If saving is successful, increment the module ID
      const nextModuleIndex = SatmoduleIds.indexOf(currentModuleId) + 1;
      if (nextModuleIndex < SatmoduleIds.length) {
        setCurrentModuleId(SatmoduleIds[nextModuleIndex]);
        setCurrentQuestion(0);
        setUserAnswers({});
        setQuizResult(null);
        setStartTime(Date.now()); // Reset start time for the first question of the new module
      } else {
        navigate(`/course-details/${courseId}/${testId}/scorecard`);
      }
    } catch (error) {
      console.error("Error saving quiz result:", error);
      // Handle error if necessary
    }
  };

  const handleAnswerBoxClick = (index) => {
    const currentTime = Date.now();
    const timeSpent = currentTime - startTime;

    setTimeSpentPerQuestion((prevTimes) => ({
      ...prevTimes,
      [currentQuestion]: timeSpent,
    }));

    setCurrentQuestion(index);
    setStartTime(currentTime); // Reset start time for the selected question
    setQuizResult(null);
  };

  // const handleAnswerBoxClick = (index) => {
  //   setCurrentQuestion(index);
  //   setQuizResult(null);
  // };

  const DesmosCalculator = () => {
    // navigate('/desmos')
    window.open("/desmos", "_blank", "width=600,height=450,top=100,left=100");
  };

  const ReferencesSheet = () => {
    window.open("/sheet", "_blank", "width=800,height=550,top=100,right=100");
  };

  const handleMarkForReview = (questionIndex) => {
    setMarkedForReview((prevMarkedForReview) => {
      const newMarkedForReview = { ...prevMarkedForReview };
      if (newMarkedForReview[questionIndex]) {
        delete newMarkedForReview[questionIndex];
      } else {
        newMarkedForReview[questionIndex] = true;
      }
      return newMarkedForReview;
    });
  };

  return (
    <>
      {isLoading || !totalQuestions || totalQuestions === 0 ? (
        <div className="padding">
          <div className="test-preparation mt-5">
            <h2>We're Preparing Your Practice Test</h2>
            <div className="loading-section">
              <img
                src="/assets/hourglass.png"
                alt="Loading..."
                className="hourglass-img"
              />
              <p>
                This may take up to a minute. Please don't refresh this page or
                quit the app.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="padding-quiz">
          <>
            <div className="d-flex justify-content-between timer-section">
              <div className="ps-5 mt-4">
                <h6>
                  {" "}
                  {name[SatmoduleIds.indexOf(currentModuleId)]} :{" "}
                  {area[SatmoduleIds.indexOf(currentModuleId)]}
                </h6>
              </div>
              <div className="timer">
                <bold>
                  {Math.floor(secondsRemaining / 60)}:
                  {(secondsRemaining % 60).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}
                </bold>
              </div>

              <div className="pe-5 d-flex">
                <div className="calculator" onClick={DesmosCalculator}>
                  <img src="/assets/calculator.svg" />
                  <p>Calculator</p>
                </div>
                <div className="ms-3 calculator" onClick={ReferencesSheet}>
                  <img src="/assets/calculator.svg" />
                  <p>References</p>
                </div>
              </div>
            </div>
            {quizResult === null ? (
              <div>
                {currentQuestionData.type === "MCQ" ? (
                  <div className="math-quiz-container">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6">
                        <div className="pt-5">
                          {/* Question Number */}
                          <div className="question-number d-flex">
                            <p className="q-number">{currentQuestion + 1}</p>
                            <div
                              className="ms-3"
                              onClick={() =>
                                handleMarkForReview(currentQuestion)
                              }
                            >
                              <img
                                src={
                                  markedForReview[currentQuestion]
                                    ? "/assets/save_fill.png"
                                    : "/assets/save_empty.png"
                                }
                              />
                              <small className="ms-2 mark_review">
                                Mark for Review
                              </small>
                            </div>
                          </div>

                          {/* Scenarios Details */}
                          <div className="pt-2">
                            <div className="text-center">
                              {currentQuestionData.scenarioImageUrl && (
                                <img
                                  src={currentQuestionData.scenarioImageUrl}
                                  alt="Scenario"
                                  className="scenarioImage"
                                />
                              )}
                            </div>
                            {currentQuestionData.scenario1 ? (
                              currentQuestionData.scenario1.includes("\\(") ? (
                                <MathJaxContext input="tex">
                                  <MathJax>
                                    {ReactHtmlParser(
                                      currentQuestionData.scenario1
                                    )}
                                  </MathJax>
                                </MathJaxContext>
                              ) : (
                                ReactHtmlParser(currentQuestionData.scenario1)
                              )
                            ) : null}
                            <br />
                            <p className="description text-center">
                              {ReactHtmlParser(currentQuestionData.scenario2)}
                            </p>
                          </div>

                          {/* Answers choices */}
                          <div className="">
                            {currentQuestionData.question.text ? (
                              currentQuestionData.question.text.includes(
                                "\\("
                              ) ? (
                                <MathJaxContext input="tex">
                                  <MathJax>
                                    {ReactHtmlParser(
                                      currentQuestionData.question.text
                                    )}
                                  </MathJax>
                                </MathJaxContext>
                              ) : (
                                ReactHtmlParser(
                                  currentQuestionData.question.text
                                )
                              )
                            ) : null}

                            {/* <strong>{currentQuestionData.question.text}</strong> */}

                            {/* <strong>{currentQuestionData.question.text.includes("\\(") ? (
                                <MathJaxContext input="tex">
                                  <MathJax>
                                    {ReactHtmlParser(
                                      currentQuestionData.question.text
                                    )}
                                  </MathJax>
                                </MathJaxContext>
                              ) : (
                                ReactHtmlParser(currentQuestionData.question.text)
                              )}</strong> */}
                            <div className="mcq-answer-section mt-2">
                              {currentQuestionData.choices.map(
                                (choice, index) => (
                                  <div
                                    // key={index}
                                    // className={`mcq-choice ${
                                    //   choice.content === selectedAnswerIndex
                                    //     ? "selected"
                                    //     : ""
                                    // }`}
                                    // onClick={() =>
                                    //   handleAnswerSelection(
                                    //     choice.content,
                                    //     choice.choiceId
                                    //   )
                                    // }
                                    key={index}
                                    className={`mcq-choice ${
                                      userAnswers[currentQuestion] &&
                                      userAnswers[currentQuestion]
                                        .selectedAnswer === choice.choiceId
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleAnswerSelection(
                                        choice.content,
                                        choice.choiceId
                                      )
                                    }
                                  >
                                    {/* <div className="mcq-choice-letter">
                                      {String.fromCharCode(65 + index)}
                                    </div> */}
                                    <div
                                      className={`mcq-choice-letter ${
                                        userAnswers[currentQuestion] &&
                                        userAnswers[currentQuestion]
                                          .selectedAnswer === choice.choiceId
                                          ? "selected-letter"
                                          : ""
                                      }`}
                                    >
                                      {String.fromCharCode(65 + index)}
                                    </div>
                                    {choice.content.includes("\\(") ? (
                                      // If the choice contains a math equation
                                      <MathJaxContext input="tex">
                                        <MathJax style={{ display: "block" }}>
                                          {ReactHtmlParser(choice.content)}

                                          {/* {choice.content} */}
                                        </MathJax>
                                      </MathJaxContext>
                                    ) : (
                                      // If the choice contains simple HTML tags
                                      ReactHtmlParser(choice.content)
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </div>
                ) : (
                  <div className="sat-quiz-container">
                    <div className="row">
                      <div className="question-section col-lg-6">
                        <div className="pt-5 pb-5">
                          <div className="">
                            <h6>Student produce response directions</h6>
                            <ul>
                              <li>
                                If you find{" "}
                                <strong>more than one correct answers,</strong>{" "}
                                enter only one answer.
                              </li>
                              <li>
                                If your answer is a <strong>fraction</strong>{" "}
                                that doesn't fit in the provided space, enter
                                the decimal equivalent.
                              </li>
                              <li>
                                If your answer is a <strong>decimal</strong>{" "}
                                that doesn't fit in the provided space, enter it
                                by truncating or rounding at the fourth digit.
                              </li>
                              <li>
                                Don't enter <strong>symbols</strong> such as
                                percent sign, comma or dollar sign.
                              </li>
                              <li>
                                If you find{" "}
                                <strong>more than one correct answers,</strong>{" "}
                                enter only one answer.
                              </li>
                              <li>
                                If your answer is a <strong>fraction</strong>{" "}
                                that doesn't fit in the provided space, enter
                                the decimal equivalent.
                              </li>
                              <li>
                                If your answer is a <strong>decimal</strong>{" "}
                                that doesn't fit in the provided space, enter it
                                by truncating or rounding at the fourth digit.
                              </li>
                              <li>
                                Don't enter <strong>symbols</strong> such as
                                percent sign, comma or dollar sign.
                              </li>
                              <li>
                                Don't enter <strong>symbols</strong> such as
                                percent sign, comma or dollar sign.
                              </li>
                            </ul>
                          </div>

                          <br />
                        </div>
                      </div>
                      <div className="col-lg-6 answer-section ps-4 pt-5 pb-5">
                        <div className="question-number">
                          <p className="q-number">{currentQuestion + 1}</p>
                        </div>
                        <div className="pt-3">
                          <div className="text-center">
                            {currentQuestionData.scenarioImageUrl && (
                              <img
                                src={currentQuestionData.scenarioImageUrl}
                                alt="Scenario"
                                className="scenarioImage"
                              />
                            )}
                          </div>
                          <strong>
                            {currentQuestionData.scenario1 ? (
                              currentQuestionData.scenario1.includes("\\(") ? (
                                <MathJaxContext input="tex">
                                  <MathJax>
                                    {ReactHtmlParser(
                                      currentQuestionData.scenario1
                                    )}
                                  </MathJax>
                                </MathJaxContext>
                              ) : (
                                ReactHtmlParser(currentQuestionData.scenario1)
                              )
                            ) : null}
                          </strong>
                          <br />
                          <strong>
                            {currentQuestionData.scenario2 ? (
                              currentQuestionData.scenario2.includes("\\(") ? (
                                <MathJaxContext input="tex">
                                  <MathJax>
                                    {ReactHtmlParser(
                                      currentQuestionData.scenario2
                                    )}
                                  </MathJax>
                                </MathJaxContext>
                              ) : (
                                ReactHtmlParser(currentQuestionData.scenario2)
                              )
                            ) : null}
                          </strong>
                          <br />
                          {currentQuestionData.question.text ? (
                            currentQuestionData.question.text.includes(
                              "\\("
                            ) ? (
                              <MathJaxContext input="tex">
                                <MathJax>
                                  {ReactHtmlParser(
                                    currentQuestionData.question.text
                                  )}
                                </MathJax>
                              </MathJaxContext>
                            ) : (
                              ReactHtmlParser(currentQuestionData.question.text)
                            )
                          ) : null}
                          {/* <strong>{currentQuestionData.question.text}</strong> */}
                        </div>
                        <div className="mcq-answer-section mt-4">
                          {/* <input
                            type="text"
                            className="fib-box"
                            value={userInput}
                            onChange={handleInputChange} // Attach the event handler
                          /> */}
                          <input
                            type="text"
                            className="fib-box"
                            value={
                              userAnswers[currentQuestion]
                                ? userAnswers[currentQuestion].userInput
                                : ""
                            }
                            onChange={handleInputChange}
                          />
                          <div className="preview mt-5">
                            <h6>Answer Preview</h6>
                            <p>
                              {userAnswers[currentQuestion]
                                ? userAnswers[currentQuestion].userInput
                                : userInput}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="quiz-container-sat result p-5">
                <div className="text-center">
                  <h2>Check Your Work</h2>
                  <p>
                    On test day, you won't be able to move on to the next module
                    until time expires.
                  </p>
                  <p>
                    For these practice questions, you can click{" "}
                    <strong>Submit</strong> when you are ready to move on.
                  </p>
                </div>

                <div className="box-answer">
                  <div className="d-flex justify-content-between">
                    <h2>{currentQuestionData.title}</h2>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          marginRight: "20px",
                        }}
                      >
                        <span className="unanswered-question-box"></span>
                        <p className="text-inline">Unanswered</p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <img
                          className="review-question-box"
                          src="/assets/save_fill.png"
                        />
                        <p className="text-inline">For Review</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="answer-box">
                    {coursesData?.map((question, index) => (
                      <div
                        key={index}
                        className={
                          userAnswers[index] !== undefined
                            ? "answered-question"
                            : "unanswered-question"
                        }
                        onClick={() => handleAnswerBoxClick(index)}
                      >
                        <div className="question-number-box">{index + 1}</div>
                      </div>
                    ))}
                  </div> */}
                  <div className="answer-box">
                    {coursesData?.map((question, index) => (
                      <div
                        key={index}
                        className={
                          userAnswers[index] !== undefined
                            ? "answered-question"
                            : "unanswered-question"
                        }
                        onClick={() => handleAnswerBoxClick(index)}
                      >
                        <div className="question-number-box">{index + 1}</div>
                        {markedForReview[index] && (
                          <img
                            src="/assets/save_fill.png"
                            className="review-icon"
                            alt="Marked for Review"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center bottom_footer">
              <div>
                <h6 className="ps-5">{user.data.user.firstName} {user.data.user.lastName}</h6>
              </div>
              {quizResult === null ? (
                <div className="quest-number">
                  <div className="custom-tooltip-container">
                    <p
                      className="questions-number"
                      onClick={() => setTooltipVisible(!tooltipVisible)}
                    >
                      Questions {currentQuestion + 1} of {totalQuestions}
                    </p>
                    {tooltipVisible && (
                      <div className="custom-tooltip">
                        <span onClick={navigateToPage}>Go to Page</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="buttons-class">
                <button
                  className="quiz-button"
                  onClick={handleBackButtonClick}
                  disabled={currentQuestion === 0}
                >
                  Back
                </button>
                <button
                  className="quiz-button"
                  onClick={() =>
                    quizResult !== null
                      ? handleSubmitButtonClick()
                      : handleNextButtonClick()
                  }
                >
                  {quizResult !== null ? "Submit" : "Next"}
                </button>
              </div>
            </div>
          </>
        </div>
      )}
      {/* <Loader loading={isLoading} /> */}
    </>
  );
};

export default MathModule;
