import React from "react";
import Copyrights from "../copyrights/copyrights";
import PageVideo from "../landing-page/page-video/page-video";
import Footer from "../page-footer/page-footer";
import PageNavbar from "../page-navbar/page-navbar";
import AboutBanner from "./about-banner/about-banner";
import OurAim from "./our-aim/our-aim";
import OurGoals from "./our-goals/our-goals";
import OurTutor from "./our-tutors/our-tutors";
import WhatWeDeliver from "./what-we-deliver/what-we-deliver";

const AboutUs = () => {
  return (
    <>
      <PageNavbar />
      <AboutBanner />
      {/* <OurAim /> */}
      <OurGoals />
      <WhatWeDeliver />
      {/* <OurTutor /> */}
      <PageVideo />
      <Footer />
      {/* <Copyrights /> */}
    </>
  );
};

export default AboutUs;
