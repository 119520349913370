import React from "react";
import "./book-appointment.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const BookAppointment = () => {
  const initialValues = {
    email: "",
  };
  const onSubmit = () => {};
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is required!"),
  });

  return (
    <div className="book-appointment mb-5 mt-5">
      <div className="container appointment pt-5 pb-2">
        <div className="appointment-content text-center">
          <h1 className="text-heading">Book an Appointment Today</h1>
          {/* <p className="para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}
        </div>
        <div className="appointment-input pt-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="mb-5">
            <div className="d-inline-flex">
              <Field
                className="appointment-form-input placeholder"
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your Email Address"
              />
              <button type="button" className="btn btn-submit">
                  Submit
              </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BookAppointment;
