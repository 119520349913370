// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   user: {},
//   isLogin: false,
// };

// const usersSlice = createSlice({
//   name: "users",
//   initialState,
//   reducers: {
//     logoutUser(state) {
//       state.user = {};
//       state.isLogin = false;
//     },
//     updateUser(state, action) {
//       state.user = action.payload
//       state.isLogin = true;
//     }
//   },
// });

// const courseSlice = createSlice({
//   name: "courses",
//   initialState: initialCourseState,
//   reducers: {
//     setCourseId(state, action) {
//       state.courseId = action.payload;
//     },
//     setCourseDetails(state, action) {
//       state.courseDetails = action.payload;
//     },
//     clearCourse(state) {
//       state.courseId = null;
//       state.courseDetails = {};
//     },
//   },
// });


// export const { logoutUser, updateUser } = usersSlice.actions;
// export default usersSlice.reducer;

// export const { setCourseId, setCourseDetails, clearCourse } = courseSlice.actions;


import { createSlice } from "@reduxjs/toolkit";

// Initial state for users slice
const initialUserState = {
  user: {},
  isLogin: false,
};

// Users slice
const usersSlice = createSlice({
  name: "users",
  initialState: initialUserState,
  reducers: {
    logoutUser(state) {
      state.user = {};
      state.isLogin = false;
    },
    updateUser(state, action) {
      state.user = action.payload;
      state.isLogin = true;
    },
  },
});

// Initial state for courses slice
const initialCourseState = {
  courseId: null,
  courseDetails: {},
};

// Courses slice
const courseSlice = createSlice({
  name: "courses",
  initialState: initialCourseState,
  reducers: {
    setCourseId(state, action) {
      state.courseId = action.payload;
    },
    setCourseDetails(state, action) {
      state.courseDetails = action.payload;
    },
    clearCourse(state) {
      state.courseId = null;
      state.courseDetails = {};
    },
  },
});

// Exporting actions
export const { logoutUser, updateUser } = usersSlice.actions;
export const { setCourseId, setCourseDetails, clearCourse } = courseSlice.actions;

// Exporting reducers (without using default)
export const usersReducer = usersSlice.reducer;
export const coursesReducer = courseSlice.reducer;
