import React from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Dashboard = () => {
  const { user, isLogin } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const navigateToProfile = () => {
    navigate("/my-profile");
  };

  return (
    <>
    {/* <h2 className="color">Welcome, Asad Wali </h2> */}
    <h2 className="color">
                      Welcome, {user?.data?.user
                        ? `${user.data.user.firstName} ${user.data.user.lastName}`
                        : "Guest"}{" "}
                    
                    </h2>
      <div className="claim-discount mt-4 pt-3 pb-3 ps-5 pe-5 d-flex justify-content-between">
        <div>
          <h4 className="color">Claim Your Discount</h4>
          <h6 className="color">Please complete your profile at www.apscholars.com</h6>
          <button className="dashboard-button mt-3" onClick={navigateToProfile}>Complete Your Profile</button>
        </div>
        <div className="image-display">
          <img src="/assets/hand.png" alt="ClaimDiscount" />
        </div>
      </div>

      <div className="claim-offer mt-4 pt-3 pb-4 ps-5 pe-5 d-flex justify-content-between">
        <div>
          <h4 className="color">Special Offer</h4>
          <h6 className="color">Submit your class material and get up to $50 Amazon gift card</h6>
          <button className="dashboard-button mt-3">Submit Material</button>
        </div>
        <div className="image-display">
          <img src="/assets/offer.png" alt="OfferDiscount" />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
