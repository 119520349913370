import React, { useEffect, useRef } from 'react';
import Desmos from 'desmos';

const DesmosCalculator = () => {
  const calculatorRef = useRef(null);

  useEffect(() => {
    const elt = calculatorRef.current;
    const calculator = Desmos.GraphingCalculator(elt);

    // Set some default expressions or configurations if needed
    calculator.setExpression({ id: 'graph1', latex: 'y=x^2' });

    return () => {
      calculator.destroy(); // Clean up the calculator instance
    };
  }, []);

  return <div ref={calculatorRef} style={{ width: '600px', height: '400px' }} />;
};

export default DesmosCalculator;
